import React, { useState, useEffect } from "react";
import { Nav, Tab, Form, FormControl } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "../Whatsapp/Pagination";
import "./AdminDashboard.css";

const TabbedLogs = ({ userID }) => {
  const [activeKey, setActiveKey] = useState("whatsapp");

  // WhatsApp Logs Component
  const WhatsAppLogs = () => {
    const [loading, setLoading] = useState(false);
    const [Campaign, setCampaign] = useState([]);
    const [startDate, setStartDate] = useState(getOneWeekBackDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
    
    const getLogs = async () => {
      try {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

        var raw = JSON.stringify({
          userid: "A1001",
          fromDate: startDate,
          toDate: endDate
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `https://api.anlook.com/api/Whatsapp/getAllWhatsappLogs`,
          requestOptions
        );

        const data = await res.json();

        if (data?.responseCode == "00") {
          setCampaign(data.response);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      getLogs();
    }, [startDate, endDate]);

    const totalPages = Math.ceil(Campaign.length / itemsPerPage);
    const displayedItems = Campaign.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    return (
      <LogTable
        title="WhatsApp Message Logs"
        loading={loading}
        data={displayedItems}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        columns={whatsAppColumns}
      />
    );
  };

  // Payment Logs Component
  const PaymentLogs = () => {
    const [startDate, setStartDate] = useState(getOneWeekBackDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const paymentData = [
      { id: 1, transactionId: "TRX001", amount: 100, method: "Credit Card", date: "2024-03-25", status: "completed" },
      { id: 2, transactionId: "TRX002", amount: 75, method: "PayPal", date: "2024-03-24", status: "pending" },
      { id: 3, transactionId: "TRX003", amount: 150, method: "Bank Transfer", date: "2024-03-23", status: "failed" },
    ];

    const totalPages = Math.ceil(paymentData.length / itemsPerPage);

    return (
      <LogTable
        title="Payment Logs"
        loading={false}
        data={paymentData}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        columns={paymentColumns}
      />
    );
  };

  // Order Logs Component
  const OrderLogs = () => {
    const [startDate, setStartDate] = useState(getOneWeekBackDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const orderData = [
      { id: 1, orderId: "ORD001", customer: "John Doe", items: 3, total: 150, date: "2024-03-25", status: "shipped" },
      { id: 2, orderId: "ORD002", customer: "Jane Smith", items: 2, total: 95, date: "2024-03-24", status: "processing" },
      { id: 3, orderId: "ORD003", customer: "Bob Johnson", items: 1, total: 50, date: "2024-03-23", status: "delivered" },
    ];

    const totalPages = Math.ceil(orderData.length / itemsPerPage);

    return (
      <LogTable
        title="Order Logs"
        loading={false}
        data={orderData}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        columns={orderColumns}
      />
    );
  };

  // Reusable Log Table Component
  const LogTable = ({
    title,
    loading,
    data,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    currentPage,
    setCurrentPage,
    totalPages,
    columns
  }) => {
    const handlePageClick = (page) => setCurrentPage(page);
    const handleNextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
    const handlePrevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

    return (
      <div className="p-4 bg-white" style={{ height: "100vh", fontFamily: "Nunito,sans-serif" }}>
        <div className="px-4 justify-content-center">
          <Form className="d-flex justify-content-between pb-4">
            <Form.Label className="CreateCampaign">{title}</Form.Label>
            <div className="d-flex">
              <label htmlFor="start-date" className="single-line-css d-flex align-items-center">
                Start Date
              </label>
              <FormControl
                type="date"
                id="start-date"
                value={startDate}
                className="bg-light me-5 ms-2"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label htmlFor="end-date" className="single-line-css d-flex align-items-center">
                End Date
              </label>
              <FormControl
                type="date"
                id="end-date"
                value={endDate}
                className="bg-light ms-2"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </Form>
          
          {loading ? (
            <div className="m-5 p-5 d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr className="text-center greenColour">
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgb(25,135,84)",
                          borderRadius: index === 0 ? "7px 0px 0px 7px" : 
                                       index === columns.length - 1 ? "0px 7px 7px 0px" : "",
                        }}
                      >
                        {column.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #DDD" }}>
                      {columns.map((column, colIndex) => (
                        <td key={colIndex} className="p-3 text-center">
                          {column.render ? column.render(item) : item[column.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pb-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePrevPage}
                  onPageClick={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  // Utility functions
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const getOneWeekBackDate = () => {
    const oneWeekBack = new Date();
    oneWeekBack.setDate(oneWeekBack.getDate() - 7);
    return oneWeekBack.toISOString().split('T')[0];
  };

  // Column definitions
  const whatsAppColumns = [
    { header: 'S.No.', key: 'index', render: (_, index) => index + 1 },
    { header: 'Type', key: 'category' },
    { header: 'Name', key: 'contact_name' },
    { header: 'Mobile Number', key: 'contact' },
    { header: 'Time', key: 'indate' },
    {
      header: 'Status',
      key: 'status',
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: `solid 1px ${item.status === "read" ? "#007E59" : "#000"}`,
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  const paymentColumns = [
    { header: 'S.No.', key: 'index', render: (_, index) => index + 1 },
    { header: 'Transaction ID', key: 'transactionId' },
    { header: 'Amount', key: 'amount', render: (item) => `$${item.amount}` },
    { header: 'Method', key: 'method' },
    { header: 'Date', key: 'date' },
    {
      header: 'Status',
      key: 'status',
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: "solid 1px #007E59",
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  const orderColumns = [
    { header: 'S.No.', key: 'index', render: (_, index) => index + 1 },
    { header: 'Order ID', key: 'orderId' },
    { header: 'Customer', key: 'customer' },
    { header: 'Items', key: 'items' },
    { header: 'Total', key: 'total', render: (item) => `$${item.total}` },
    { header: 'Date', key: 'date' },
    {
      header: 'Status',
      key: 'status',
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: "solid 1px #007E59",
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  return (
    <div className="marginRight mt-4">
      <div className="container-fluid text-center">
        <ToastContainer />
      </div>
      <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        <Nav variant="tabs" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="whatsapp">WhatsApp Logs</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="payment">Payment Logs</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="order">Order Logs</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="whatsapp">
            <WhatsAppLogs />
          </Tab.Pane>
          <Tab.Pane eventKey="payment">
            <PaymentLogs />
          </Tab.Pane>
          <Tab.Pane eventKey="order">
            <OrderLogs />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default TabbedLogs;