import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "./AdminDashboard.css";


import logoImage from "../../images/Anlook White.svg";

import staticsIcon from "../../images/Statistics.svg";
import staticsIcon1 from "../../images/Statistics-green.png";

import Logout from "../../images/Logout (1).svg";
import Logout1 from "../../images/Logout (1)-green.png";

const SideNav = ({ logout, Admin }) => {
  const [activeItem, setActiveItem] = useState(() => {
    const savedState = localStorage.getItem("myState");
    return savedState ? JSON.parse(savedState) : "home";
  });
  const [Loading, setLoading] = useState();
  const [Access, setAccess] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("myState", JSON.stringify(activeItem));
  }, [activeItem]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 80) {
        // Adjust this value based on when you want the nav to become sticky
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  // fetch All Client Api call
  const FetchUserAccessCall = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const UserDetail = {
        // userid: User.pkid,
        userid: Admin.pkid,
      };

      const response = await axios.post(
        "https://api.anlook.com/api/Admin/getUserAccess",
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        // toast.success(response.data.responseMessage);

        setAccess(response.data.response);
        console.log(response.data.response);
        console.log(Access);
        setLoading(false);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("error");
      console.error("Error updating Admin login:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    FetchUserAccessCall();
  }, []);

  return (
    <nav className={`side-nav ${isSticky ? "sticky" : ""}`} ref={navRef}>
      <div className="logo Brandlog px-5">
        <img src={logoImage} alt="Anlook" style={{ height: "30px" }} />
      </div>
      <ul>
        {Access?.map((Options, Index) => (
          <li
            className={activeItem === Options.menu_title ? "active" : ""}
            onClick={() => handleItemClick(Options.menu_title)}
          >
            <Link className="nav-link" to={Options.menu_url}>
              <div className="d-flex align-items-center py-3">
                <div className="pe-1">
                  <img
                    src={
                      activeItem === Options.menu_title
                        ? staticsIcon1
                        : staticsIcon
                    }
                    alt=""
                  />
                </div>
                {Options.menu_title}
              </div>
            </Link>
          </li>
        ))}

        <li
          className={activeItem === "statistics" ? "active" : ""}
          onClick={logout}
        >
          <Link className="nav-link">
            <div className="d-flex align-items-center py-3">
              <div className="pe-1">
                <img
                  src={activeItem === "statistics" ? Logout1 : Logout}
                  alt=""
                />
              </div>
              Logout
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default SideNav;
