import React from "react";
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
import { Helmet } from 'react-helmet';

const Cancellation = ({onSignUpClick, onLoginClick }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Anlook Cancellation Policy",
    "description": "Understand Anlook's cancellation policy for our WhatsApp automation tool. Learn about the process, conditions, and any applicable fees for cancelling your subscription. Manage your service with ease.",
    "keywords": "Anlook cancellation policy, service cancellation, subscription management, cancellation process, cancellation terms, Anlook subscription, service termination, cancellation fees, WhatsApp automation",
    "mainEntity": {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What is Anlook's return/refund/cancellation policy?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "We only offer digital products, so refunds, cancellations, or returns are not possible after an order has been placed. If you have a continuing subscription, you can cancel anytime, and no charges will be deducted after you cancel the subscription or membership. However, any charges deducted before cancellation of membership cannot be refunded."
          }
        },
        {
          "@type": "Question",
          "name": "How can I cancel my subscription?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can cancel your subscription anytime, and no charges will be deducted after you cancel the subscription or membership."
          }
        },
        {
          "@type": "Question",
          "name": "Can I get a refund for charges deducted before cancellation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No, any charges deducted before cancellation of membership cannot be refunded."
          }
        },
        {
          "@type": "Question",
          "name": "Who can I contact for further questions regarding cancellation or refunds?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "For any other questions regarding cancellation or refunds, please send us an email at support@anlook.com."
          }
        }
      ]
    }
  };
  return (
    <>



      <Helmet>
        <title>Anlook Cancellation Policy - Hassle-Free Service Management</title>
        <meta name="description" content="Understand Anlook's cancellation policy for our WhatsApp automation tool. Learn about the process, conditions, and any applicable fees for cancelling your subscription. Manage your service with ease." />
        <meta name="keywords" content="Anlook cancellation policy, service cancellation, subscription management, cancellation process, cancellation terms, Anlook subscription, service termination, cancellation fees, WhatsApp automation" />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>



      <div className=" landing-body-bg">
        <LandingNav onSignUpClick={onSignUpClick} onLoginClick={onLoginClick} />
        <div className="padding-5-rem " style={{height:"100vh"}}>
          <div className="row">
            <div className="col-12">
              <div className=" border-radius-20 pb-5 ">
                <div className="text-2-2  py-4 pb-5 ">Return/ Refund/ Cancellation Policy</div>
                <div className="">
                  <p>
                  We only offer digital products, so refunds, cancellations, or returns are not possible after an order has been placed. If you have a continuing subscription, you can cancel anytime, and no charges will be deducted after you cancel the subscription or membership. However, any charges deducted before cancellation of membership cannot be refunded.
                  </p>
                  <p className="text-3-2">
                  For any other questions regarding cancellation or refunds, please send us an email at
                   <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@anlook.com" target="_blank" rel="noopener noreferrer">
   {" "} support@anlook.com
  </a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};
export default Cancellation;
