import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Modal,
  Row,
  Table,
  Tooltip,
  OverlayTrigger,
  Carousel,
} from "react-bootstrap";
import Select from "react-select";
import swal from "sweetalert";
export const Authorblogmodal = ({
  Authormodal,
  setAuthormodal,
  GetAuthorsBlog,
}) => {
  const [newAuthor, setNewAuthor] = useState({
    p_name: "",
    p_username: "",
    p_email: "",
    p_bio: "",
    p_profile_image: "",
    p_website_url: "",
    p_social_links: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAuthor({ ...newAuthor, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const raw = JSON.stringify({
        p_name: newAuthor.p_name,
        p_username: newAuthor.p_username,
        p_email: newAuthor.p_email,
        p_bio: newAuthor.p_bio,
        p_profile_image: newAuthor.p_profile_image,
        p_website_url: newAuthor.p_website_url,
        p_social_links: "string",
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/CreateAuthors`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        // Handle the 'result' array and success message
        const result = data.result[0];
        if (result && result.result === "Y") {
          swal("Success", result.message, "success");
          GetAuthorsBlog();
        } else {
          swal("Error", "Unexpected result format", "error");
        }
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      swal("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <Modal show={Authormodal} onHide={() => setAuthormodal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Author</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="p_name"
                value={newAuthor.p_name}
                onChange={handleInputChange}
                placeholder="Enter author's name"
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="p_username"
                value={newAuthor.p_username}
                onChange={handleInputChange}
                placeholder="Enter author's username"
              />
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="p_email"
                value={newAuthor.p_email}
                onChange={handleInputChange}
                placeholder="Enter author's email"
              />
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Bio</Form.Label>
              <textarea
                name="p_bio"
                value={newAuthor.p_bio}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter author's bio"
              />
            </Form.Group>
          </div>
          <div className="row m-0 p-0">
            <Form.Group className="col">
              <Form.Label>Profile Image</Form.Label>
              <Form.Control
                type="text"
                name="p_profile_image"
                value={newAuthor.p_profile_image}
                onChange={handleInputChange}
                placeholder="Enter profile image URL"
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="url"
                name="p_website_url"
                value={newAuthor.p_website_url}
                onChange={handleInputChange}
                placeholder="Enter website URL"
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setAuthormodal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add Author
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const Uploadimageblog = ({
  showModal,
  handleCloseModal,
  setContent,
  uploadedImageUrl,
  handleImageUpload,
}) => {
  const [imageData, setImageData] = useState({
    title: "",
    alt: "",
    description: "",
  });
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setContent((prevContent) => [
      ...prevContent,
      {
        type: "uploadedImage",
        src: uploadedImageUrl,
        ...imageData,
      },
    ]);
    handleCloseModal();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setImageData({
      ...imageData,
      [name]: value,
    });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Uploaded Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Upload Image </Form.Label>
            <Form.Control
              type="file"
              id="image-upload"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
            />
          </div>

          {uploadedImageUrl && (
            <div className="row m-0 p-0">
              <div className="col-2 ">
                <img
                  src={uploadedImageUrl}
                  alt="Uploaded Preview"
                  style={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "2px solid #ddd",
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <h6 className="">URL: {uploadedImageUrl}</h6>
              </div>
            </div>
          )}

          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Image Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={imageData.title}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter image title"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageAlt">Image Alt Text</Form.Label>
            <Form.Control
              type="text"
              name="alt"
              value={imageData.alt}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter image alt text"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageDescription">
              Image Description
            </Form.Label>
            <textarea
              name="description"
              value={imageData.description}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter image description"
            />
          </div>

          <button type="submit" className="btn btn-primary mt-3">
            Save
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const Uploadfeatureimageblog = ({
  featureModal,
  handleClosefeatureModal,
  setContent,
  uploadedfeatureImageUrl,
  setSubmitted,
  handlefeatureImageUpload,
  setFeatureimageData,
  featureimageData,
}) => {
  const handlefeatureInputChange = (e) => {
    const { name, value } = e.target;
    setFeatureimageData({
      ...featureimageData,
      [name]: value,
    });
  };
  const handlefeatureFormSubmit = (e) => {
    e.preventDefault();
    setContent((prevContent) => [
      ...prevContent,
      {
        // type: "uploadedImage",
        src: uploadedfeatureImageUrl,
        ...featureimageData,
      },
    ]);
    setSubmitted(true);
    handleClosefeatureModal();
  };
  return (
    <Modal show={featureModal} onHide={handleClosefeatureModal}>
      <Modal.Header closeButton>
        <Modal.Title>Uploaded Feature Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlefeatureFormSubmit}>
          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Upload Image </Form.Label>
            <Form.Control
              type="file"
              id="image-upload"
              accept="image/*"
              multiple
              onChange={handlefeatureImageUpload}
            />
          </div>

          {uploadedfeatureImageUrl && (
            <div className="row m-0 p-0">
              <div className="col-2 ">
                <img
                  src={uploadedfeatureImageUrl}
                  alt="Uploaded Preview"
                  style={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "2px solid #ddd",
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <h6 className="">URL: {uploadedfeatureImageUrl}</h6>
              </div>
            </div>
          )}

          <div className="form-group">
            <Form.Label htmlFor="imageTitle">Image Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={featureimageData.title}
              onChange={handlefeatureInputChange}
              className="form-control"
              placeholder="Enter image title"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageAlt">Image Alt Text</Form.Label>
            <Form.Control
              type="text"
              name="alt"
              value={featureimageData.alt}
              onChange={handlefeatureInputChange}
              className="form-control"
              placeholder="Enter image alt text"
            />
          </div>

          <div className="form-group">
            <Form.Label htmlFor="imageDescription">
              Image Description
            </Form.Label>
            <textarea
              name="description"
              value={featureimageData.description}
              onChange={handlefeatureInputChange}
              className="form-control"
              placeholder="Enter image description"
            />
          </div>

          <button type="submit" className="btn btn-primary mt-3">
            Save
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};