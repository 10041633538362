import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Modal from "react-bootstrap/Modal";
import Track from "../../TrackingPage"
import Logo from "../../images/MY-Anlook.png";
import LogoutImage from "../../images/logout.svg";
import Women from "../../images/My-landing-img1.png";
import Travel from "../../images/travel_tourism_1x.webp";
import Fintech from "../../images/fintech_finances_1x.webp";
import Health from "../../images/health_wellness_1x.webp";
import Education from "../../images/education_ed_tech_2x.webp";
import Food from "../../images/food_restaurant_2x.webp";
import WomenWitheMobile from "../../images/footer_1x.webp";
import blueTick from "../../images/LandingPageImage/blueTick.svg";
import redcross from "../../images/LandingPageImage/redcross.svg";
import dropdown from "../../images/LandingPageImage/dropDown.svg";
import { toast, ToastContainer } from "react-toastify";
import ExternalChatBot from "../Landing_Page/Home_Page/ExternalChatBot";
import Tick from "../../images/GREENTICK.png";
import Cross from "../../images/REDCROSS.png";
import Campaign from "../../images/campaign_analytics_1x.webp";
import Order from "../../images/order_management_1x.webp";
import Team from "../../images/inbox_team_1x.webp";
import BroadCast from "../../images/broadcast_1x.webp";

function MyLanding({ onLoginClick }) {
  const pricingRef = useRef(null);
  const location = useLocation();
  const [plans, setPlans] = useState([]);
  const [isAnnual, setIsAnnual] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);


  const trackClick = async (requestBody) => {
    try {
      const response = await axios.post('https://api.goseo.in/api/Seo/trackClickHeatMaps', requestBody);
      console.log('Click tracked successfully:', response.data);
      return true;
    } catch (error) {
      console.error('Error tracking click:', error);
      return false;
    }
  };
  

  // Function to handle button click
  const handleButtonClick_SEO = async (buttonName) => {
 
    const urlParams = new URLSearchParams(window.location.search);

    const referrer = document.referrer === "" ? "direct" : document.referrer;
    console.log("Referrer:", referrer);


    const requestBody = {
      campaignid: 'campaign_id',
      sessionid: urlParams.get("sessionid"),
      x_cord: '123', // Example coordinates
      y_cord: '456',
      element: 'Button', 
      button_name: buttonName, 
      pageurl: window.location.href, 
      pagename: 'Landing Page',
      product: 'Anlook',
      redirection_url: window.location.href,
    };

    const seoTracked = await trackClick(requestBody);

    // Only proceed to login if the SEO tracking was successful
    if (seoTracked) {
      if (buttonName === 'Login') {
        login();
      } else if (buttonName === 'Get Started') {
        scrollToPricing();
      }
    }
  };











  // State to handle modal visibility and URL to display
  const [showContentModal, setShowContentModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");

  // Function to handle link clicks and open the modal
  const handleLinkClick = (url) => (e) => {
    e.preventDefault();
    setIframeUrl(url); // Set the URL for the iframe
    setShowContentModal(true); // Show the modal
  };

  // Function to handle modal close
  const handleContentClose = () => setShowContentModal(false);

  // Function to handle modal close
  const buyPlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleSwitch = () => {
    setIsAnnual(!isAnnual);
  };
  const [pricingData, setPricingData] = useState({
    title: `Unlock the Best WhatsApp Business API Pricing with Super `,
    title2: "Affordable Plans & Premium Services!",
    plans: [
      {
        name: "Basic Plan",
        price: "₹2,000",
        gst: "+ GST",
        monthlyFee: "₹2,000",
      },
      {
        name: "Standard Plan",
        price: "₹5,000",
        gst: "+ GST",
        monthlyFee: "₹5,000",
      },
      {
        name: "Gold Plan",
        price: "₹8,000",
        gst: "+ GST",
        monthlyFee: "₹8,000",
      },
    ],
    features: [
      {
        name: "Website",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
      {
        name: "Application",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
      { name: "Chat Automation", values: [true, true, true, true] },
      { name: "Automated Workflows", values: [true, true, true, true] },
      { name: "Roles & Permissions", values: [true, true, true, true] },
      { name: "SEO/SMO", values: [false, "Basic", "Intermediate", "Advanced"] },
      {
        name: "Shared Team Inbox",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
      {
        name: "Unlimited Team Members",
        values: ["Basic", "Basic+", "Intermediate", "Advanced"],
      },
    ],
    footerText: [
      "One Time Cost @5000 + GST",
      "Monthly packages start at ₹2000",
    ],
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);

  const updateCarousel = () => {
    if (containerRef.current) {
      const cardWidth =
        containerRef.current.querySelector(".My-Landing-card").offsetWidth;
      containerRef.current.style.transform = `translateX(-${
        currentIndex * cardWidth
      }px)`;
    }
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    const totalCards = containerRef.current.children.length;
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, totalCards - 1));
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    updateCarousel();
  }, [currentIndex, isMobile]);
  // features section

  const [activeFeature, setActiveFeature] = useState("Broadcast Engagement");
  const [isChanging, setIsChanging] = useState(false);

  const features = [
    "Broadcast Engagement",
    "Campaign Analytics",
    "Team Inbox",
    "Order Management",
  ];

  const featureContent = {
    "Broadcast Engagement": {
      title: "Broadcast Engagement",
      description: `Evaluate your campaign success with Anlook, which provides the tools and insights to refine your marketing strategies for improved engagement, conversions, and business growth. Monitor performance effortlessly, gain precise insights, and understand what resonates most with your audience. With capabilities to gauge ROI, segment audiences, and enhance content strategies, Anlook transforms your marketing efforts into powerful drivers of success.`,
      image: BroadCast,
    },
    "Campaign Analytics": {
      title: "Campaign Analytics",
      description: `Our platform delivers instant query responses by swiftly assigning each customer inquiry to the right expert, enhancing team collaboration and efficiency. With detailed productivity tracking, you can monitor performance metrics, optimize workflows, and increase conversions. This comprehensive solution streamlines operations and elevates customer service, driving your business towards greater success.`,
      image: Campaign,
    },
    "Team Inbox": {
      title: "Team Inbox",
      description: `Effortlessly manage one-time or recurring campaigns with our platform, customizing and scheduling alerts to match customer preferences. Automatically deliver personalized messages that enhance engagement and drive conversions. With user-friendly features, track campaign performance, refine strategies based on real-time insights, and maintain consistent communication. Empower your marketing efforts with our intuitive system, designed to streamline campaign management and maximize audience impact.`,
      image: Team,
    },
    "Order Management": {
      title: "Order Management",
      description:
        "Transform your sales journey by setting up your WhatsApp storefront today. Offer customers an enhanced shopping experience where they can browse, inquire, and purchase with ease, boosting satisfaction and loyalty. Our comprehensive solutions equip you with powerful tools to manage orders, inventory, and customer interactions, ensuring smooth operations and accelerated growth. Embrace the trend of businesses enhancing customer engagement through personalized service and efficient transactions. ",
      image: Order,
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = features.indexOf(activeFeature);
      const nextIndex = (currentIndex + 1) % features.length;
      setIsChanging(true);
      setTimeout(() => {
        setActiveFeature(features[nextIndex]);
        setIsChanging(false);
      }, 300);
    }, 5000);

    return () => clearInterval(interval);
  }, [activeFeature]);


  const handleButtonClick = (feature) => {
    if (feature !== activeFeature) {
      setIsChanging(true);
      setTimeout(() => {
        setActiveFeature(feature);
        setIsChanging(false);
      }, 300);
    }
  };

  const scrollToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const login = () => {
    const productId = "1";
    const callbackUrl = "https://www.anlook.com/get";
    // const callbackUrl = `${window.location.origin}/get`;

    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${productId}`;
    window.location.href = redirectUrl;
  };
 





  

  const trackPageView = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const referrer = document.referrer === "" ? "direct" : document.referrer;
    console.log("Referrer:", referrer);

    const trackingData = {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      product: "Anlook",
    };

    fetch(`https://api.goseo.in/api/Seo/CreateSession`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(trackingData),
    })
      .then((response) => {
        console.log("Response from server:", response);
        return response.json();
      })
      .then((data) => console.log("Page view tracked successfully:", data))
      .catch((error) => console.error("Error tracking page view:", error));
  };

  const trackPageView2 = () => {
    console.log("Page view tracking started...");

    const urlParams = new URLSearchParams(window.location.search);
    console.log("URL parameters:", urlParams.toString());

    const referrer = document.referrer === "" ? "direct" : document.referrer;
    console.log("Referrer:", referrer);

    const trackingData = {
      campaignid: urlParams.get("campaignid"),
      sessionid: urlParams.get("sessionid"),
      page_url: window.location.href,

      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_term: urlParams.get("utm_term"),
      utm_content: urlParams.get("utm_content"),
      referrer: referrer,
      user_agent: navigator.userAgent,
      timestamp: new Date().toISOString(),
      product: "Anlook",
    };

    console.log("Tracking data to be sent:", trackingData);

    fetch(`https://api.goseo.in/api/Seo/insertPageTracking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(trackingData),
    })
      .then((response) => {
        console.log("Response from server:", response);
        return response.json();
      })
      .then((data) => console.log("Page view tracked successfully:", data))
      .catch((error) => console.error("Error tracking page view:", error));
  };

  const [accessToken, setAccessToken] = useState("Not Found");
  const [user, setUser] = useState({});

  // Function to verify the access token
  const verifyAccessToken = async (token) => {
    try {
      const response = await fetch(
        "https://api.anlook.com/api/User/ssoLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
            product: "anlook",
          }),
        }
      );

      const data = await response.json();

      // Check if the response code is "00" for success
      if (data.responseCode === "00") {
        setUser(data.response); // Store user data in the state
        console.log("Access Token Verified:", data.response);
      } else {
        console.error("Access Token Invalid:", data.responseMessage);
      }
    } catch (error) {
      console.error("Error verifying token:", error);
    }
  };

  useEffect(() => {
    console.log("Component mounted or URL changed"); // Check if useEffect is firing

    // Get the full URL query string
    const queryString = window.location.search;

    // Replace the second `?` with `&` to make the query string valid
    const fixedQueryString = queryString.replace(/\?([^?]+)$/, "&$1");

    // Use the fixed query string with URLSearchParams
    const params = new URLSearchParams(fixedQueryString);
    const token = params.get("accessToken");

    console.log("Token from URL:", token); // Debug log for token value

    if (token) {
      setAccessToken(token);
      verifyAccessToken(token);
    } else {
      console.log("No accessToken found in URL");
    }
  }, []);

  const fetchSubscriptionPlans = async () => {
    const url = 'https://api.anlook.com/api/Entitlement/fetchSubscriptionPlans';
    const requestBody = {
      userid: "",
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
      },
    };
    try {
      
      const response = await axios.post(url, requestBody,config);

      setPlans(response.data.data); // Assuming the data is in response.data.data
      // setLoading(false);
    } catch (error) {
      // setError('Error fetching subscription plans');
      // setLoading(false);
      console.error('Error fetching subscription plans:', error);
    }
  };



  useEffect(() => {
    trackPageView();
    trackPageView2();
    fetchSubscriptionPlans();
  }, []);

  const logout = () => {
    setUser(null);
  };

  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };
  const buyPlanClickAPI = async (planId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const location = await getUserLocation();
      const requestBody = {
        userid: user.userid,
        planid: planId,
        latitude: location.latitude.toString(),
        longitude: location.longitude.toString(),
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Entitlement/buySubscriptionPlan",
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        window.location.href = response.data.data.paymentLink;
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred. Please try again.");
    }
  };
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("Phone:", phone);
    console.log("Message:", message);
    setName("");
    setPhone("");
    setMessage("");
    setShowForm(false);
  };
  const handleCloseClick = () => {
    setShowForm(false);
  };
  const [callBackForm, setCallBackForm] = useState({
    mobile: "",
    email: "",
    name: "",
    message: "",
    source: "",
    callback: "Y",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCallBackForm({
      ...callBackForm,
      [name]: value,
    });
  };
  const validateForm = () => {
    const newErrors = {};
    if (!callBackForm.name) {
      newErrors.name = "Name is required";
      toast.error("Name is required");
    } else if (!callBackForm.mobile) {
      newErrors.mobile = "Mobile number is required";
      toast.error("Mobile number is required");
    } else if (!/^\d{10}$/.test(callBackForm.mobile)) {
      newErrors.mobile = "Invalid mobile number";
      toast.error("Invalid mobile number");
    } else if (!callBackForm.message) {
      newErrors.message = "Message is required";
      toast.error("Message is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const callbackAPI = async () => {
    if (!validateForm()) return;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        mobile: callBackForm.mobile,
        email: callBackForm.email,
        name: callBackForm.name,
        message: callBackForm.message,
        source: callBackForm.source,
        callback: callBackForm.callback,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/User/saveLeadMessage",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
          // Clear the form after success
          setCallBackForm({
            mobile: "",
            email: "",
            name: "",
            message: "",
            source: "",
            callback: "Y",
          });
      } else {
        toast.error("Failed to edit contact");
      }
    } catch {
      console.log("");
    }
  };
  return (
    <div className="gradient-blur">
      <Track/>
      <ExternalChatBot/>
      <ToastContainer />
      <Modal show={showModal} onHide={handleClose} centered size="lg" className="custom-modal">
  <Modal.Header closeButton className="modal-header-custom">
    <Modal.Title className="modal-title-custom">
      {selectedPlan?.plan_name} Plan
    </Modal.Title>
  </Modal.Header>
  
  <Modal.Body className="modal-body-custom">
  <div className="custom-pricing-card">
  <div className="custom-plan-info">
    <p className="custom-plan-details d-flex align-items-center">
      <span className="custom-plan-name pe-3">
        {selectedPlan?.plan_name} Plan {" "} :
      </span>
      <span className="custom-plan-price">
        {selectedPlan?.plan_price ? `₹${Number(selectedPlan.plan_price).toFixed(0) }` : "Price not available"}
      </span>
      <span className="ps-1 custom-plan-duration">
      {selectedPlan?.plan_duration}
    </span>
    </p>
    
    {selectedPlan?.setup_fee === "5000.000000" ?
      <>
         <p className="custom-plan-details d-flex align-items-center">
      <span className="custom-plan-name pe-3">
       Setup Fee :
      </span>

     
      <span className="custom-plan-price">
      ₹5,000
      </span>
      <span className="ps-1 custom-plan-duration">
      One-Time Fee
    </span>
     
    </p>
      </>:
      <>
      </>
      }


 
  </div>
  
  <div className="custom-total-cost">
  <h4 className="custom-total-cost-heading">Total Price</h4>
  <p className="custom-total-cost-amount mb-0">
    ₹ {selectedPlan?.total_price}
   

  </p>
</div>
</div>

    {/* Plan Details Section */}
    {/* <div className="modal-plan-details-section">
      <h4>Plan Details</h4>
      <p><strong>User Seats:</strong> {selectedPlan?.user_seats}</p>
      <p><strong>Conversations per Year:</strong> {selectedPlan?.conversations_per_year}</p>
       <p>{selectedPlan?.description}</p>
    </div> */}
  
    {/* Feature List Section */}
    <div className="custom-modal-feature-list-section">
  <h4>Features</h4>
  <ul className="custom-modal-feature-list">
  {selectedPlan?.features
  .filter(feature => feature.status === 'Y') // Filter only features with status 'Y'
  .map((feature, index) => (
    <li key={index} className="custom-modal-feature-item">
      {feature.feature_name}
    </li>
  ))}
  </ul>
</div>
  </Modal.Body>
  
  <Modal.Footer className="modal-footer-custom">
  <button onClick={() => { buyPlanClickAPI(selectedPlan.id) }} className="btn btn-primary btn-custom">
        Proceed to Payment
    </button>



   
  </Modal.Footer>
</Modal>



      <Modal show={showContentModal} onHide={handleContentClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Embed the content with an iframe */}
          <iframe
            src={iframeUrl}
            style={{ width: "100%", height: "400px", border: "none" }}
            title="Content Modal"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="blue-button" onClick={handleContentClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* navbar section  */}
      <div className="my-landing-p mx-2 mx-lg-0 py-0 ">
      <section class="My-landing-nav py-0">
      <nav class="  ">
        <section className="navbar My-Landing-engagement-platform py-3 d-flex justify-content-between align-items-center">
          <img className="My-logo" src={Logo} alt="logo" />

          <div className="My-nav-buttons d-flex align-items-center">
            {!user || !user.username ? (
              <>
                <button className="Landing-btn" onClick={() => handleButtonClick_SEO('Login')}>
                  Login
                </button>
              </>
            ) : (
              <div className="user-info d-flex align-items-center">
                <div className="user-details">
                  <span className="username">{user.username}</span>
                  <span className="mobile">{user.mobile}</span>
                </div>

                <div className="tooltip-container">
                  <button className="logout-btn" onClick={() => logout()}>
                    <img src={LogoutImage} height={20} alt="logout" />
                  </button>
                  <span className="tooltip-text">Logout</span>
                </div>
              </div>
            )}
          </div>
        </section>
      </nav>
      </section>
      </div>
      {/* hero section  */}
      {/* {accessToken}
      <div>{JSON.stringify(user)}</div> */}

      <div className="my-landing-p ">
        <section class="My-Landing-engagement-platform py-0 center-align">
          <div class="hero-content ">
            <h1 class="hero-title font-size-1">
              Take Your Business Marketing to the Next Level with Anlook!
            </h1>
            <p class="hero-description font-size-3 ">
              Supercharge sales with WhatsApp Business API, automating
              marketing, sales, service, and support for personalized customer
              communication
            </p>
            <ul class="feature-list font-size-3 ">
              <li>Broadcast Promotional Offers to Unlimited Users</li>
              <li>Sell your Products on WhatsApp using Catalogs</li>
              <li>Automate Messages via Integrations</li>
              <li>Enable Multi-Agent Live Chat for Customer Support</li>
              <li>Build Chatbots & Solve for 24 x 7 Support & Engagement</li>
            </ul>
            <div class="cta-buttons">
              <button class="Landing-btn" onClick={() => handleButtonClick_SEO('Get Started')}>
                Get Started
              </button>
              <button class="Landing-btn-2" onClick={() => handleButtonClick_SEO('Get Started')}>
                See Pricing
              </button>
            </div>
          </div>
          <div class="hero-image ">
            <img     className="img-fluid home-responsive-img "  src={Women} alt=""/>
          </div>
        </section>
      </div>
      {/* video section */}
      <div className="my-landing-p">
        <section class="My-Landing-engagement-platform">
          <h1 class="My-Landing-main-title font-size-1">
            Advanced WhatsApp Engagement and Conversion Platform
          </h1>
          <div class="My-Landing-platform-content">
            {/* <div class="My-Landing-logo">ANLOCK</div> */}
            {/* <h2 class="My-Landing-subtitle">
              Automate, Engage, Grow your Business with Us
            </h2> */}
            <div class="My-Landing-video-placeholder py-3 px-lg-5 px-0">
              <iframe
                className="custom-video-frame"
                src="https://www.youtube.com/embed/UhWKUcaywqo"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              {/* <div class="My-Landing-play-button"></div> */}
            </div>

            {/* <div class="My-Landing-banner">STAY CONNECTED 24/7 WITH ANLO0K</div> */}
          </div>
        </section>
      </div>
      {/* video section end */}
      {/* features section */}
      <div className="bg-white">
        <div className="My-Landing-features ">
          <h1 className="My-Landing-features-title font-size-1">
            Discover Anlook's Power-Packed Features for Unmatched <br />
            Business Growth!
          </h1>
          <div className="main-responsive-flex mb-4 mb-lg-5">
            {features.map((feature) => (
              <button
                key={feature}
                className={`My-Landing-feature-button w-100 ${
                  activeFeature === feature ? "active" : ""
                }`}
                onClick={() => handleButtonClick(feature)}
              >
                {feature}
              </button>
            ))}
          </div>
          <div className="My-Landing-feature-content">
            <img
              src={featureContent[activeFeature].image}
              className={`My-Landing-feature-image-placeholder ${
                isChanging ? "changing" : ""
              }`}
              alt="img"
              // style={{ backgroundColor: featureContent[activeFeature].color }}
            ></img>
            <div
              className={`My-Landing-feature-text ${
                isChanging ? "changing" : ""
              }`}
            >
              <h2>{featureContent[activeFeature].title}</h2>
              <p>{featureContent[activeFeature].description}</p>
              <button
                className="My-Landing-get-started Landing-btn"
                onClick={() => handleButtonClick_SEO('Get Started')}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* features section end */}
      {/* Get started section */}
      <div className="my-landing-p2">
        <div className="my-landing-p ">
          <div class="My-Landing-cta-banner">
            <div class="My-Landing-cta-text font-size-1">
              Start optimizing your business with <br /> Anlook today
            </div>

            <div class="main-responsive-flex">
              <button
                class=" Landing-btn"
                onClick={() => handleButtonClick_SEO('Get Started')}
              >
                Get Started
              </button>
              <button
                class=" Landing-btn-2"
                onClick={() => handleButtonClick_SEO('Get Started')}
              >
                See Pricing
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Get started section end */}
      {/* bussiness for all section */}
      <div className="bg-white">
        <section className="My-Landing-whatsapp-marketing">
          <h2 className="My-Landing-section-title font-size-1">
            WhatsApp Marketing for Every Business,
            <br />
            Transform Your Growth with Anlook
          </h2>

          <div
            className={`My-Landing-card-carousel ${
              isMobile ? "mobile-view" : ""
            }`}
          >
            <div className="My-Landing-card-container" ref={containerRef}>
              {/* Your existing card components here */}
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Travel}
                  alt="Travel"
                />
                <h3 className="font-size-2">Travel & Tourism</h3>
                <p className="font-size-3">
                  Share travel deals, updates, and guides, keeping travellers
                  informed and engaged.
                </p>
              </div>
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Fintech}
                  alt="Fintech"
                />
                <h3 className="font-size-2">Fintech Solution</h3>
                <p className="font-size-3">
                  You can send alerts, payment reminders, and tips, improving
                  customer communication and financial management.
                </p>
              </div>
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Health}
                  alt="Health"
                />
                <h3 className="font-size-2">Health & Wellness</h3>
                <p className="font-size-3">
                  Send appointment reminders, promote health campaigns, and give
                  health tips, improving patient engagement and outcomes.
                </p>
              </div>
              <div className="My-Landing-card">
                <img className="My-Landing-card-image" src={Food} alt="Food" />
                <h3 className="font-size-2">Food & Beverages</h3>
                <p className="font-size-3">
                  Share your menus, discounts, order updates, and event news to
                  make customers happier and more loyal.
                </p>
              </div>
              <div className="My-Landing-card">
                <img
                  className="My-Landing-card-image"
                  src={Education}
                  alt="Education"
                />
                <h3 className="font-size-2">Ed-Tech</h3>
                <p className="font-size-3">
                  Anlook helps schools inform students and parents about events,
                  deadlines, courses, and academic resources.
                </p>
              </div>
            </div>
          </div>

          <div className="My-Landing-carousel-controls">
            <button
              className="My-Landing-carousel-btn My-Landing-prev"
              onClick={handlePrevClick}
              disabled={currentIndex === 0}
            >
              &lt;
            </button>
            <button
              className="My-Landing-carousel-btn My-Landing-next"
              onClick={handleNextClick}
              disabled={
                currentIndex === containerRef.current?.children.length - 1
              }
            >
              &gt;
            </button>
          </div>
        </section>
      </div>
      {/* Get started section */}
      <div className="my-landing-p2 ">
        <div className="my-landing-p ">
          <div class="My-Landing-cta-banner">
            <div class="My-Landing-cta-text font-size-1">
              Start optimizing your business with <br /> Anlook today
            </div>

            <div class="main-responsive-flex">
              <button
                class=" Landing-btn"
                onClick={() => handleButtonClick_SEO('Get Started')}
              >
                Get Started
              </button>
              <button
                class=" Landing-btn-2"
                onClick={() => handleButtonClick_SEO('Get Started')}
              >
                See Pricing
              </button>
            </div>
          </div>
        </div>{" "}
      </div>
      {/* why choose us section  */}
      <div className="bg-white">
        <div className="anlook-benefits">
          <h2 className="anlook-benefits__title font-size-1">
            More reasons to choose Anlook
            <br />
            for your Business growth
          </h2>
          <div className="anlook-benefits__grid">
            <div className="anlook-benefits__card">
              <div className="anlook-benefits__icon anlook-benefits__icon--megaphone"></div>
              <h3 className="anlook-benefits__card-title font-size-2 ">
                Explore Anlook's versatile capabilities for your success.
              </h3>
              <p className="anlook-benefits__card-description font-size-3">
                Generate high-intent leads with Anlook. Engage customers
                throughout their journey using Anlook's notification engine.
                Keep them updated with automated shipping and delivery updates.
                Track campaign performance to see what worked.
              </p>
            </div>
            <div className="anlook-benefits__card">
              <div className="anlook-benefits__icon anlook-benefits__icon--hand"></div>
              <h3 className="anlook-benefits__card-title font-size-2 ">
                Increase Sales & Maximize Conversions on WhatsApp
              </h3>
              <p className="anlook-benefits__card-description font-size-3">
                Transform conversations into conversions with Anlook. Nudge
                nurtured leads through the sales funnel using WhatsApp.
                Personalize upsell and cross-sell offers based on interactions.
                Seamlessly accept payments on WhatsApp with integrated payment
                solutions.
              </p>
            </div>
            <div className="anlook-benefits__card">
              <div className="anlook-benefits__icon anlook-benefits__icon--person"></div>
              <h3 className="anlook-benefits__card-title font-size-2 ">
                Scale your support with WhatsApp.
              </h3>
              <p className="anlook-benefits__card-description font-size-3">
                Enhance customer experience and build brand credibility with
                Anlook's 24×7 support. Use one number for all business
                communication and create custom auto-replies for high-volume
                conversations. Monitor response and resolution times to ensure
                top-notch service.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing section ref={pricingRef} */}
      <div className="bg-white">
    
        <h1 className="My-Landing-pricing-title font-size-1 px-2">
          {pricingData.title}
          <br />
          {pricingData.title2}
        </h1>
        <div className="pricing-cards-container pt-lg-5 pt-1" ref={pricingRef}>
        {plans?.map(plan => (
  <div 
    className={`pricing-card-wrapper ${plan.is_enabled !== "Y" ? "disabled-card" : ""}`} 
    key={plan.id}
  >
    <div className={`pricing-card ${plan.plan_name === "Standard" ? "highlight-card" : ""}`}>
      <div className="pricing-card-header">
        <h3>{plan.plan_name}</h3>
        {plan.plan_name === "Standard" && <span className="badge">Most Popular</span>}
      </div>
      <div className="pricing-card-body">
        <div className="price">
          ₹ {Number(plan.plan_price).toFixed(0)} <span className="price-duration">{plan.plan_duration}</span>
        </div>
        {plan.setup_fee === "5000.000000" && (
          <p className="setup-cost-note">
            <strong>+ One-time setup fee of ₹5,000</strong>
          </p>
        )}
        {plan.setup_fee === "0.000000" && (
          <p className="setup-cost-note">
            <strong>One-time setup not included</strong>
          </p>
        )}
        <p className="description">{plan.plan_desc}</p>
       

        {!user || !user.username ? (
                    <>
                      <button
                      
                      onClick={() => {
                        handleButtonClick_SEO('login');
                        login();
                      }}




                      className="buy-button">
                      {plan.button_text} →
                      </button>
                      
                    </>
                  ) : (
                    <button

                      onClick={() => {
                        handleButtonClick_SEO('buy_plan');
                        buyPlanClick(plan);
                      }}


                      className="buy-button"
                      disabled={plan.is_enabled !== "Y"}
                    >
                      {plan.button_text} →
                    </button>
                  )} 
        <div className="feature-list pt-3">
        {plan.features
  .filter((feature) => feature.status === 'Y') // Filter features with status 'Y'
  .slice(0, showMore ? plan.features.length : 6) // Slice first 8 features
  .map((feature, index) => (
    <p key={index} className="feature-item">
      <img src={blueTick} alt="feature tick" />
      {feature.feature_name}
    </p>
  ))}
        </div>
      </div>
    </div>
  </div>
))}

        </div>

        <div className="d-flex justify-content-center py-5 ">
          <button
            onClick={toggleShowMore}
            className="gray-button bg-white text-gray "
            style={{ border: "1px solid #ccc", borderRadius: "20px" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              {showMore ? "View less details" : "View all Feature details"}
              <span className="ms-1">
                <img src={dropdown} alt="dropdown" />
              </span>
            </div>
          </button>
        </div>
      </div>
      {/* pricing section end */}

      {/* contact and query section */}
      <div className="My-landing-contact-container">
        <div className="My-landing-image-section">
          <img
          className="img-fluid home-responsive-img pb-3" 
            src={WomenWitheMobile}
            alt="ImagePlaceholder"
          ></img>
          <h2 className="My-landing-title font-size-1">
            We are here
            <br />
            to help your business
          </h2>
          <p className="My-landing-description ">
            Contact us with any questions; our team is dedicated to providing
            prompt responses and assistance
          </p>
        </div>
        <div className="My-landing-form-section">
          <form className="My-landing-contact-form">
            <input
              type="text"
              className={`form-control My-landing-input ${
                errors.name ? "is-invalid" : ""
              }`}
              placeholder="Enter your name"
              name="name"
              value={callBackForm.name}
              onChange={handleChange}
            />
            <input
              type="tel"
              className={`form-control My-landing-input ${
                errors.mobile ? "is-invalid" : ""
              }`}
              placeholder="Enter contact number"
              name="mobile"
              value={callBackForm.mobile}
              onChange={handleChange}
            />
            {/* <input
              type="email"
              placeholder="Work Email"
              className="My-landing-input"
            /> */}
            <textarea
              className={`form-control My-landing-input My-landing-textarea ${
                errors.message ? "is-invalid" : ""
              }`}
              placeholder="Message"
              name="message"
              value={callBackForm.message}
              onChange={handleChange}
            ></textarea>
            <button
              type="button"
             
              onClick={() => {
                handleButtonClick_SEO('contact_us_submit');
                callbackAPI();
              }}
              className="My-landing-submit-button Landing-btn"
            >
              <>Submit</>
            </button>
          </form>
        </div>
      </div>
      {/* contact and query section end  */}
      {/* footer section */}
      <footer className="My-Landing-footer">
        <div className="My-Landing-footer-content">
          <p className="My-Landing-copyright">
            © 2024 Anlook. All rights reserved.
          </p>
          <nav className="My-Landing-footer-nav">
            <a
              href="https://www.anlook.com/T&C"
              className="My-Landing-footer-link"
              onClick={handleLinkClick("https://www.anlook.com/T&C")}
            >
              Terms & Conditions
            </a>
            <span className="My-Landing-footer-separator">|</span>
            <a
              href="https://www.anlook.com/PrivacyPolicy"
              className="My-Landing-footer-link"
              onClick={handleLinkClick("https://www.anlook.com/PrivacyPolicy")}
            >
              Privacy Policy
            </a>
          </nav>
        </div>
      </footer>

      {/* Footer section ending */}
    </div>
  );
}
export default MyLanding;
