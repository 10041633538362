import React, { useState, useRef, useEffect, useContext } from "react";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaImage,
  FaVideo,
  FaParagraph,
  FaLink,
  FaQuoteRight,
} from "react-icons/fa";
import Select from "react-select";
import { ImQuotesLeft } from "react-icons/im";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Form,
  Modal,
  InputGroup,
  Tab,
} from "react-bootstrap";
import { Authorblogmodal, Uploadfeatureimageblog } from "./Modal";
import swal from "sweetalert";
import { AiOutlineClose } from "react-icons/ai";
import "./AdminDashboard.css";

const BasicEditor = () => {
  const buttonStyle = {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "6px",
    cursor: "pointer",
    fontSize: "16px",
  };

  const spanStyle = {
    cursor: "pointer",
    marginRight: "10px",
    marginLeft: "20px",
  };

  const [content, setContent] = useState("");
  const [fontSize, setFontSize] = useState("16px");
  const [fontFamily, setFontFamily] = useState("Arial");
  const editorRef = useRef(null);
  const [tempPostId, setTempPostId] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [savedRange, setSavedRange] = useState(null);

  const handleEditorClick = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setSavedRange(range); // Save the range when clicking inside the editor
    }
  };
  const handleInput = () => {
    if (editorRef.current) {
      setContent(editorRef.current.innerHTML);
      console.log(editorRef.current.innerHTML);
    }
  };
  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };
  useEffect(() => {
    const generatedUUID = generateUUID();
    console.log(generatedUUID);
    setTempPostId(generatedUUID);
  }, []);
  const applyStyle = (style) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    if (!range) return;

    const selectedText = range.toString();
    const parentElement = range.startContainer.parentElement;
    if (range && editorRef.current.contains(range.commonAncestorContainer)) {
      switch (style) {
        case "bold":
          document.execCommand("bold", false, null);
          break;
        case "italic":
          document.execCommand("italic", false, null);
          break;
        case "underline":
          document.execCommand("underline", false, null);
          break;
        case "h1":
        case "h2":
        case "h3":
          const tagName = style.toUpperCase();
          if (parentElement.tagName === tagName) {
            const normalText = document.createElement("span");
            normalText.innerHTML = selectedText;
            range.deleteContents();
            range.insertNode(normalText);
          } else {
            const newElement = document.createElement(style);
            newElement.innerHTML = selectedText;
            range.deleteContents();
            range.insertNode(newElement);
          }
          break;
        case "p":
          const pElement = document.createElement("p");
          pElement.innerHTML = selectedText;
          range.deleteContents();
          range.insertNode(pElement);
          break;
        case "quote":
          const blockquote = document.createElement("blockquote");
          blockquote.innerHTML = selectedText;
          range.deleteContents();
          range.insertNode(blockquote);
          break;
        case "link":
          const url = prompt("Enter the URL");
          if (url) {
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.innerHTML = selectedText;
            range.deleteContents();
            range.insertNode(anchor);
          }
          break;
        case "fontSize":
          applyInlineStyle("font-size", fontSize);
          break;
        case "fontFamily":
          applyInlineStyle("font-family", fontFamily);
          break;

        case "justifyLeft":
        case "justifyCenter":
        case "justifyRight":
          document.execCommand(style, false, null);
          break;
        default:
          break;
      }
    }
    handleInput();
  };
  const applyInlineStyle = (styleProperty, value) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const selectedText = range.toString();

    if (selectedText) {
      const span = document.createElement("span");
      span.style[styleProperty] = value;
      span.textContent = selectedText;

      range.deleteContents();
      range.insertNode(span);

      handleInput();
    }
  };
  const handleFontSizeChange = (e) => {
    const newSize = e.target.value;
    setFontSize(newSize);
    applyStyle("fontSize");
  };

  const handleFontFamilyChange = (e) => {
    const newFamily = e.target.value;
    setFontFamily(newFamily);
    applyStyle("fontFamily");
  };
  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;
            insertImage(imageUrl);
            alert(`Image uploaded successfully. URL: ${imageUrl}`);
          } else {
            alert(`Error: ${result.message}`);
          }
        } catch (error) {
          alert(`Upload failed: ${error.message}`);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handleSubmit = async (status) => {
    const htmlContent = editorRef.current.innerHTML;
    const parser = new DOMParser();
    const blocks = [];

    const createBlock = (type, content, order, imageDetails = {}) => ({
      p_temp_post_id: tempPostId,
      p_block_type: type,
      p_block_content: content || imageDetails.src || "",
      p_block_style: "",
      p_block_image: imageDetails.src || "",
      p_block_order: order,
      //   p_user_id: user.userid,
      p_user_id: "1",
      p_image_alt: imageDetails.alt || "",
      p_image_desc: imageDetails.description || "",
      p_image_title: imageDetails.title || "",
    });

    const contentArray = htmlContent.split(/(<img[^>]*>|<video[^>]*>|<br>)/g);

    contentArray.forEach((content, index) => {
      if (content.startsWith("<video")) {
        const videoElement = parser.parseFromString(content, "text/html").body
          .firstChild;
        const videoBlock = createBlock(
          "uploadedVideo",
          videoElement.src,
          index + 1
        );
        blocks.push(videoBlock);
      } else if (content.startsWith("<img")) {
        const imgElement = parser.parseFromString(content, "text/html").body
          .firstChild;
        const imageBlock = createBlock("uploadedImage", "", index + 1, {
          src: imgElement.src,
          alt: imgElement.getAttribute("alt") || "",
          description: imgElement.getAttribute("description") || "",
          title: imgElement.getAttribute("title") || "",
        });
        blocks.push(imageBlock);
      } else if (content === "<br>") {
        // Add line break to the previous block
        if (blocks.length > 0) {
          const lastBlock = blocks[blocks.length - 1];
          lastBlock.p_block_content += "<br>";
        }
      } else if (content.trim()) {
        const tempDoc = parser.parseFromString(content, "text/html");
        tempDoc.body.childNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE && node.innerHTML.trim()) {
            let blockType = "";
            let blockContent = node.innerHTML;

            switch (node.tagName) {
              case "H1":
                blockType = "h1";
                break;
              case "H2":
                blockType = "h2";
                break;
              case "H3":
                blockType = "h3";
                break;
              case "P":
                blockType = "paragraph";
                break;
              case "BLOCKQUOTE":
                blockType = "quote";
                break;
              case "A":
                blockType = "link";
                const href = node.href;
                const linkText = node.innerText;
                blockContent = `${href},${linkText}`;
                break;
              default:
                blockType = "paragraph";
            }

            // Strip out any empty tags and ensure the content has visible text
            const strippedContent = blockContent
              .replace(/<\/?[^>]+(>|$)/g, "")
              .trim();
            if (blockType && strippedContent !== "") {
              const block = createBlock(
                blockType,
                blockContent,
                blocks.length + 1
              );
              blocks.push(block);
            }
          }
        });
      }
    });

    const requestPayload = {
      requests: blocks.filter((block) => block.p_block_content.trim() !== ""), // Ensure no empty content is sent
    };

    try {
      const response = await fetch(
        `https://api.goseo.in/api/Seo/insertTempBlog`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestPayload),
        }
      );

      const result = await response.json();

      if (result.status === "0") {
        Publish(status);
      } else {
        swal("Error", result.msg, "error");
      }
    } catch (error) {
      console.error("Submission failed:", error);
      // swal("Submission failed", error.message, "error");
    }
  };

  const insertImage = (url) => {
    if (editorRef.current) {
      // Check if a valid range is saved, otherwise show a message
      if (!savedRange) {
        alert("Please click in the editor where the image should be inserted.");
        return; // Stop the image insertion if no valid range
      }

      // Restore the saved range before inserting the image
      const selection = window.getSelection();
      selection.removeAllRanges(); // Clear current selection
      selection.addRange(savedRange); // Restore the saved range

      const range = selection.getRangeAt(0);

      const img = document.createElement("img");
      img.src = url;
      img.style.maxWidth = "100%"; // Adjust as needed
      img.style.display = "block"; // Ensures the image takes up space
      img.addEventListener("click", handleImageClick); // Attach click event

      range.deleteContents();
      range.insertNode(img);

      // Move cursor to the end of the inserted image
      range.setStartAfter(img);
      range.setEndAfter(img);
      selection.removeAllRanges();
      selection.addRange(range);

      handleInput();
    }
  };

  const handleImageClick = (e) => {
    const imgElement = e.target;
    setSelectedImage(imgElement);

    setImageDetails({
      alt: imgElement.alt || "",
      title: imgElement.title || "",
      description: imgElement.description || "",
    });
    setShowImageModal(true); // Open modal
  };

  // Handle modal input changes
  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setImageDetails({ ...imageDetails, [name]: value });
  };

  // Handle modal submission
  const handleModalSubmit = () => {
    if (selectedImage) {
      selectedImage.alt = imageDetails.alt;
      selectedImage.title = imageDetails.title;
      selectedImage.setAttribute("description", imageDetails.description); // Use data-description attribute

      console.log(imageDetails.description);
    }
    setShowImageModal(false);
    handleInput();
  };

  const [showModal, setShowModal] = useState(false);
  const [imageDetails, setImageDetails] = useState({
    title: "",
    alt: "",
    description: "",
  });

  // Function to handle modal open/close
  const handleModalClose = () => setShowImageModal(false);

  const [formState, setFormState] = useState({
    title: "",
    excerpt: "",
    imageUrl: "",
    slug: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [uploadedfeatureImageUrl, setUploadedfeatureImageUrl] = useState(null);
  const handleopenfeaturemodal = (e) => {
    setfeatureModal(true);
  };

  const handleClosefeatureModal = () => {
    setfeatureModal(false);
  };
  const [featureModal, setfeatureModal] = useState(false);
  const [featureimageData, setFeatureimageData] = useState({
    title: "",
    alt: "",
    description: "",
  });
  const handlefeatureImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;

            setUploadedfeatureImageUrl(imageUrl);

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${imageUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handlefeatureChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      slug:
        name === "title" && !prevState.slugEdited
          ? generateSlug(value)
          : prevState.slug,
    }));
  };
  const handleSlugChange = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      slug: value,
      slugEdited: true,
    }));
  };
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-");
  };

  // category

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const fetchCategories = async () => {
    try {
      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setCategories(
          data.data.map((category) => ({
            label: category.category_name,
            value: category.id,
          }))
        );
      } else {
        console.error("Failed to fetch categories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchSubcategories = async (categoryId) => {
    try {
      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogSubCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ categoryid: categoryId }),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setSubcategories(
          data.data.map((subcategory) => ({
            label: subcategory.sub_category,
            value: subcategory.id,
          }))
        );
      } else {
        console.error("Failed to fetch subcategories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setSubcategories([]);
    setSelectedSubcategory(null);
    fetchSubcategories(selectedOption.value);
  };
  const handleSubcategoryChange = (selectedOption) => {
    setSelectedSubcategory(selectedOption);
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const [Authormodal, setAuthormodal] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);

  const GetAuthorsBlog = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetAuthorsBlog`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);

      if (data.status === "0") {
        setAuthors(
          data.data.map((author) => ({
            label: `${author.fullname} (${author.username})`,
            value: author.id,
          }))
        );
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };
  const handleColorChange = (color) => {
    document.execCommand("styleWithCSS", false, true); // Ensure CSS styles are used
    document.execCommand("foreColor", false, color); // Apply the selected color
  };

  // Handle editor input

  // Add input listener for logging changes
  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener("input", handleInput);
      return () => {
        editor.removeEventListener("input", handleInput);
      };
    }
  }, []);
  useEffect(() => {
    GetAuthorsBlog();
  }, []);

  const handleAuthorChange = (selected) => {
    if (selected.value === "addAuthor") {
      setAuthormodal(true);
    } else {
      setSelectedAuthor(selected);
    }
  };

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const getSuggestedKeywords = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };
      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogKeywords`,
        requestOptions
      );
      const data = await res.json();
      console.log("Suggested Keywords response:", data);

      if (data.status === "0") {
        setSuggestedKeywords(data.data);
      } else {
        console.error("Failed to fetch suggested keywords:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching suggested keywords:", error);
    } finally {
    }
  };
  useEffect(() => {
    getSuggestedKeywords();
  }, []);
  useEffect(() => {
    if (keyword) {
      const filtered = suggestedKeywords.filter((suggestion) =>
        suggestion.keyword.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  }, [keyword, suggestedKeywords]);
  const handleSelectSuggested = (suggestedKeyword) => {
    setKeywords((prev) => [
      ...prev,
      { id: suggestedKeyword.id, keyword: suggestedKeyword.keyword },
    ]);
    setKeyword("");
  };

  const handleAddKeyword = (kw = keyword) => {
    if (kw) {
      const newKeywords = kw
        .split(",")
        .map((kw) => kw.trim())
        .filter(
          (kw) => kw && !keywords.some((existing) => existing.keyword === kw)
        );

      setKeywords([
        ...keywords,
        ...newKeywords.map((kw) => ({
          keyword: kw,
          id: Date.now() + Math.random(),
        })),
      ]);

      newKeywords.forEach((newKw) => {
        Insertkeywords(newKw);
      });

      setKeyword("");
    }
  };

  const handleKeywordPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddKeyword();
    }
  };
  const handleRemoveKeyword = (id) => {
    setKeywords((prevKeywords) => prevKeywords.filter((kw) => kw.id !== id));
  };
  const Insertkeywords = async (kw) => {
    try {
      const raw = JSON.stringify({
        tempid: tempPostId,
        keyword: kw,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/InsertKeywordsBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const result = data.result[0];
        if (result && result.result === "Y") {
          console.log(result.message);
        } else {
          console.log("Unexpected result format");
        }
      } else {
        console.log(data.msg);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const Publish = async (status) => {
    try {
      const { title, excerpt, slug } = formState;
      const keywordString = keywords.map((kw) => kw.keyword).join(",");
      if (!title || title.trim() === "") {
        swal("Error", "Title is required", "error");
        return;
      }

      if (!excerpt || excerpt.trim() === "") {
        swal("Error", "Excerpt is required", "error");
        return;
      }

      if (!slug || slug.trim() === "") {
        swal("Error", "Slug is required", "error");
        return;
      }

      if (!uploadedfeatureImageUrl || uploadedfeatureImageUrl.trim() === "") {
        swal("Error", "Featured image is required", "error");
        return;
      }
      if (!selectedAuthor) {
        swal("Error", "Please select an author", "error");
        return;
      }
      if (!selectedCategory || !selectedSubcategory) {
        swal("Error", "Please select a category and subcategory", "error");
        return;
      }
      const raw = JSON.stringify({
        p_temp_post_id: tempPostId,
        p_post_title: title,
        p_post_excerpt: excerpt,
        p_post_author: selectedAuthor.value,
        p_post_featured_image: uploadedfeatureImageUrl,
        p_status: status,
        p_product_name: "Anlook",
        p_post_keywords: keywordString,
        p_post_featured_image_title: featureimageData.title,
        p_post_featured_image_desc: featureimageData.alt,
        p_post_featured_image_alt: featureimageData.description,
        p_post_category: selectedCategory.value,
        p_post_subcategory: selectedSubcategory.value,
        p_slug: slug,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/publishBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const blogResult = data.result[0].result;
        const blogMessage = data.result[0].message;

        swal({
          title: "Success!",
          text: `${data.msg} - ${blogMessage}`,
          icon: "success",
          button: "OK",
        });
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: `Publish failed: ${error.message}`,
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <div className="marginRight p-4">
      <h1 className="text-dark mb-4 ps-4">Add Blog</h1>
      <div className="m-3">
        <Authorblogmodal
          Authormodal={Authormodal}
          setAuthormodal={setAuthormodal}
          GetAuthorsBlog={GetAuthorsBlog}
        />
        <Uploadfeatureimageblog
          featureModal={featureModal}
          handleClosefeatureModal={handleClosefeatureModal}
          setContent={setContent}
          uploadedfeatureImageUrl={uploadedfeatureImageUrl}
          setSubmitted={setSubmitted}
          handlefeatureImageUpload={handlefeatureImageUpload}
          setFeatureimageData={setFeatureimageData}
          featureimageData={featureimageData}
        />
        <div className="p-1">
          <Form className="border rounded-3 p-3" style={{ backgroundColor: "rgb(1,195,125,0.1)" }}>
            <Form.Group
              className="d-flex align-items-center"
              controlId="formTitle"
            >
              <Form.Label className=" mb-0 mr-2" style={{ width: "9rem" }}>
                Enter Title
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                name="title"
                value={formState.title}
                onChange={handlefeatureChange}
              />{" "}
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center mt-2"
              controlId="formSlug"
            >
              <Form.Label className="mb-0 mr-2" style={{ width: "9rem" }}>
                Slug (URL)
              </Form.Label>
              <InputGroup style={{ height: "2.4rem" }}>
                <Form.Control
                  type="text"
                  placeholder="Enter Slug"
                  name="slug"
                  value={formState.slug}
                  onChange={handleSlugChange}
                  style={{ height: "100%", fontSize: "0.9rem" }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group
              className="d-flex align-items-center mt-2"
              controlId="formExcerpt"
            >
              <Form.Label className="mb-0 mr-2" style={{ width: "9rem" }}>
                Enter Excerpt
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add excerpt"
                name="excerpt"
                value={formState.excerpt}
                onChange={handlefeatureChange}
              />{" "}
            </Form.Group>
            <div className="row mt-3 mx-1 p-0">
              <Form.Group className="m-0 p-0 col-2" controlId="formImage">
                <p
                  className="mb-1"
                  style={{ fontSize: ".9rem", color: "grey" }}
                >
                  Select feature image
                </p>
                <Button
               className="btn btn-primary"
                  // size="sm"
                  onClick={handleopenfeaturemodal}
                >
                  Choose File
                </Button>
              </Form.Group>
              <Form.Group className="m-0 p-0 col" controlId="formImageDetails">
                {submitted && uploadedfeatureImageUrl && (
                  <div className="row m-0 p-0 ">
                    <div className="col-3">
                      <img
                        src={uploadedfeatureImageUrl}
                        alt={featureimageData.alt || "Uploaded Preview"}
                        style={{
                          width: "5rem",
                          height: "5rem",
                          borderRadius: "0.5rem",
                          objectFit: "cover",
                          border: "2px solid #ddd",
                        }}
                      />
                    </div>
                    <div className="col">
                      <h6>Title: {featureimageData.title || "No title"}</h6>
                      <h6>Alt Text: {featureimageData.alt || "No alt text"}</h6>
                      <h6>
                        Description:{" "}
                        {featureimageData.description || "No description"}
                      </h6>
                    </div>
                  </div>
                )}
              </Form.Group>
            </div>
          </Form>
        </div>
        <div className="row">
          <div className="col-md-9 col-12" >
            <div
              className="editor-toolbar mt-3 d-flex flex-wrap p-2 border rounded-3"
              style={{ marginBottom: "10px", gap: "8px" , backgroundColor: "rgb(1,195,125,0.1)" }}
            >
              <button className="bg-white" onClick={() => applyStyle("bold")} style={buttonStyle}>
                <FaBold />
              </button>
              <button className="bg-white"onClick={() => applyStyle("italic")} style={buttonStyle}>
                <FaItalic />
              </button>
              <button
                onClick={() => applyStyle("underline")}
                style={buttonStyle}
                className="bg-white"
              >
                <FaUnderline />
              </button>
              <OverlayTrigger
                placement="top"
               
                overlay={<Tooltip id="tooltip-top">H1</Tooltip>}
              >
                <Button
                  variant="outline-primary"
                  className="bg-white"
                  onClick={() => applyStyle("h1")}
                >
                  H1
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H2</Tooltip>}
              >
                <Button
                 
                  className="bg-white mx-2"
                  variant="outline-primary"
                  onClick={() => applyStyle("h2")}
                >
                  H2
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">H3</Tooltip>}
              >
                <Button
                  variant="outline-primary"
                  onClick={() => applyStyle("h3")}
                  className="bg-white"
                >
                  H3
                </Button>
              </OverlayTrigger>
              <button className="bg-white" onClick={() => applyStyle("p")} style={buttonStyle}>
                <FaParagraph />
              </button>
              <button className="bg-white" onClick={() => applyStyle("quote")} style={buttonStyle}>
                <FaQuoteRight />
              </button>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Color Picker</Tooltip>}
              >
                <button style={buttonStyle} className="bg-white">
                  <input
                    type="color"
                    id="colorPicker"
                    onChange={(e) => handleColorChange(e.target.value)}
                    style={{
                      margin: 0,
                      padding: 0,
                      width: "20px",
                      height: "20px",
                      border: "none",
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                  />
                </button>
              </OverlayTrigger>
              <button
                onClick={() => applyStyle("justifyLeft")}
                className="bg-white"
                style={buttonStyle}
              >
                <FaAlignLeft />
              </button>
              <button
                onClick={() => applyStyle("justifyCenter")}
                className="bg-white"
                style={buttonStyle}
              >
                <FaAlignCenter />
              </button>
              <button
                onClick={() => applyStyle("justifyRight")}
                className="bg-white"
                style={buttonStyle}
              >
                <FaAlignRight />
              </button>
              {/* Font Size */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Font Size</Tooltip>}
              >
                <Form.Control
                  className="px-2"
                  as="select"
                  value={fontSize}
                  onChange={handleFontSizeChange}
                  style={{
                    width: "5rem",
                    display: "inline-block",
                  }}
                >
                  <option value="16px">16px</option>
                  <option value="20px">20px</option>
                  <option value="24px">24px</option>
                  <option value="30px">30px</option>
                  <option value="40px">40px</option>
                </Form.Control>
              </OverlayTrigger>

              {/* Font Family */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Font Family</Tooltip>}
              >
                <Form.Control
                  as="select"
                  value={fontFamily}
                  onChange={handleFontFamilyChange}
                  style={{
                    width: "8rem",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <option value="Arial">Arial</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Verdana">Verdana</option>
                </Form.Control>
              </OverlayTrigger>
              {/* Image Upload */}
              <button
                onClick={() => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*";
                  input.onchange = handleImageUpload;
                  input.click();
                }}
                className="bg-white"
                style={buttonStyle}
              >
                <FaImage />
              </button>
            </div>

            <div
              ref={editorRef}
              contentEditable
              onInput={handleInput}
              onClick={handleEditorClick}
              onFocus={handleEditorClick}
              style={{
                border: "1px solid #ccc",
                minHeight: "600px",
                padding: "10px",
                borderRadius: "4px",
                fontSize: fontSize,
                fontFamily: fontFamily,
              }}
              className="editor-content"
            ></div>

            <style>
              {`
        .editor-content blockquote {
          border-left: 4px solid #ccc;
          margin: 1.5em 10px;
          padding: 0.5em 10px;
          color: #666;
          font-style: italic;
          background-color: #f9f9f9;
        }
        `}
            </style>
            <div className="m-2">
              <Button
                size="sm"
                onClick={() => handleSubmit("Draft")}
                variant="primary"
                className="mt-2"
              >
                Save as Draft
              </Button>
              <Button style={{ border: "none", background: "#fff" }}></Button>
              <Button
                size="sm"
                onClick={() => handleSubmit("published")}
                variant="primary"
                className="mt-2"
              >
                Publish
              </Button>
            </div>
          </div>
          <div className="col-md-3 p-0 col-12">
            <div className="card mt-md-3 w-100 p-2 mb-2 shadow-none" style={{ backgroundColor: "rgb(1,195,125,0.1)" }} >
              <h6 className="text-center">
                <strong>Author</strong>{" "}
              </h6>
              <Select
                options={[
                  { label: "Add Author", value: "addAuthor" },
                  ...authors,
                ]}
                onChange={handleAuthorChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 200,
                    overflowY: "auto",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: ".9rem",
                  }),
                }}
              />
            </div>
            <div className="card w-100 p-2 mb-2 shadow-none"style={{ backgroundColor: "rgb(1,195,125,0.1)" }} >
              <h6 className="text-center">
                <strong>Category</strong>{" "}
              </h6>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                options={categories}
                placeholder=" Category"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 200,
                    overflowY: "auto",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: ".9rem",
                  }),
                }}
              />

              {selectedCategory && (
                <>
                  <h6 className="mt-3">Select Subcategory</h6>
                  <Select
                    value={selectedSubcategory}
                    onChange={handleSubcategoryChange}
                    options={subcategories}
                    placeholder="Select Subcategory"
                    isDisabled={subcategories.length === 0}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: 200,
                        overflowY: "auto",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: ".9rem",
                      }),
                    }}
                  />
                </>
              )}
            </div>
            <div className="card w-100 p-2 mb-2 shadow-none" style={{ backgroundColor: "rgb(1,195,125,0.1)" }} >
              <h6 className="text-center">
                <strong>Keywords</strong>{" "}
              </h6>
              <Form inline className="">
                <Form.Control
                  type="text"
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  placeholder="Enter keywords separated by commas"
                  className="mr-2"
                  style={{ width: "100%" }}
                  onKeyPress={handleKeywordPress} // Call function on Enter key press
                />
              </Form>
              {filteredSuggestions.length > 0 && (
                <div className="suggested-keywords mt-2">
                  <strong>Suggested Keywords:</strong>
                  <div className="d-flex flex-wrap">
                    {filteredSuggestions.map((suggested) => (
                      <div
                        key={suggested.id}
                        className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSelectSuggested(suggested)}
                      >
                        {suggested.keyword}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <Tab.Container id="keyword-tabs" defaultActiveKey="keywords">
                <Tab.Content>
                  <Tab.Pane eventKey="keywords">
                    <div className="d-flex flex-wrap">
                      {keywords.length > 0 ? (
                        <>
                          {keywords.map((kw) => (
                            <div
                              key={kw.id}
                              className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                              style={{
                                position: "relative",
                                margin: "5px",
                                padding: "5px 10px",
                              }}
                            >
                              {kw.keyword} &nbsp; &nbsp;
                              <AiOutlineClose
                                style={{
                                  position: "absolute",
                                  right: "5px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveKeyword(kw.id)}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="text-center mt-4">
                          No keywords found
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>

        <Modal show={showImageModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Image Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formAltText">
                <Form.Label>Alt Text</Form.Label>
                <Form.Control
                  type="text"
                  name="alt"
                  value={imageDetails.alt}
                  onChange={handleModalInputChange}
                  placeholder="Enter image alt text"
                />
              </Form.Group>
              <Form.Group controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={imageDetails.title}
                  onChange={handleModalInputChange}
                  placeholder="Enter image title"
                />
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={imageDetails.description}
                  onChange={handleModalInputChange}
                  placeholder="Enter image description"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default BasicEditor;
