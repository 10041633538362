import React, { useEffect, useState } from "react";
import axios from "axios";
import NoIndex from "../NoIndex";
import { Link } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import TopImage from "../../images/Dashboard_IMG.webp";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
} from "chart.js";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
ChartJS.register(ArcElement, ChartTooltip, ChartLegend);
const speed = 75; // Example speed of the bike
const maxSpeed = 100; // Maximum speed value
const semiDonutData = {
  datasets: [
    {
      data: [speed, maxSpeed - speed],
      backgroundColor: [
        " rgba(1, 195, 125, 1)", 
        "rgba(200, 200, 200, 0.2)",
      ],
      borderColor: [" rgba(1, 195, 125, 1)", "rgba(200, 200, 200, 0.2)"],
      borderWidth: 1,
      cutout: "80%", 
      rotation: -90,
      circumference: 180, 
    },
  ],
};
const semiDonutOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  rotation: -90,
  circumference: 180,
};
const data = [
  { name: "Jan", uv: 1000 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
];
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
const data_chat = [
  { name: "Jan", uv: 1500 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
  { name: "Jan", uv: 1500 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
];
const CustomTooltip_chat = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
const Dashboard = ({ userID }) => {
  const [dashboardData, setDashboardData] = useState("");
  const [percentage, setPercentage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderAnalytics, setorderAnalytics] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [interval, setInterval] = useState("30");
    // State for totalQuote and sentOnNetwork
    const [totalQuote, setTotalQuote] = useState(0);
    const [sentOnNetwork, setSentOnNetwork] = useState(0);
    const [semiDonutData, setSemiDonutData] = useState({
      datasets: [
        {
          data: [0, 0], 
          backgroundColor: [
            "rgba(1, 195, 125, 1)", 
            "rgba(200, 200, 200, 0.2)", 
          ],
          borderColor: [
            "rgba(1, 195, 125, 1)",
            "rgba(200, 200, 200, 0.2)", 
          ],
          borderWidth: 1,
          cutout: "80%", 
          rotation: -90, 
          circumference: 180, 
        },
      ],
    });
  
    // Semi-donut chart options
    const semiDonutOptions = {
      plugins: {
        legend: {
          display: false, // Hide legend
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
      },
      rotation: -90, // Ensure it starts from the top
      circumference: 180, // Semi-circle
    };
  const QrCodeApi = async () => {
    try {
      const requestBody = {
        userid: userID,
        secret: "string",
        message: "Hii",
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Whatsapp/getQRCode",
        requestBody
      );
      console.log(response.data.response.qr_image_url);
      if (response.data.responseCode === "00") {
        setQrCode(response.data.response.qr_image_url);
      }
    } catch {}
  };
  const downloadQRCode = (qrCodeUrl) => {
    const link = document.createElement("a");
    link.href = qrCodeUrl;
    link.download = "QRCode.png"; // Name of the file to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const DashboardAPI = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Example Authorization Header
        },
      };
      const requestbody = {
        userid: userID,
        interval: interval,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Whatsapp/getDashboardAnalytics",
        requestbody,
        config
      );

      if (response.data.responseCode === "00") {
        const { totalQuote, sentOnNetwork } = response.data.data;
        const remainingMessages = totalQuote - sentOnNetwork;

        // Update the chart data
        setSemiDonutData({
          labels: ["Sent Messages", "Available Messages"],
          datasets: [
            {
              data: [sentOnNetwork, remainingMessages], 
              backgroundColor: ["#FF6384", "rgba(1, 195, 125, 1)"],
              hoverBackgroundColor: ["#FF6384", "rgba(1, 195, 125, 1)"],
            },
          ],
        });

        // Update the state values for totalQuote and sentOnNetwork
        setTotalQuote(totalQuote);
        setSentOnNetwork(sentOnNetwork);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const orderAPI = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Example Authorization Header
        },
      };
      const requestbody = {
        userid: userID,
        interval: interval,
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Order/getOrderAnalyticsData",
        requestbody,
        config
      );

      if (response.data.responseCode === "00") {
        setorderAnalytics(response.data.response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    DashboardAPI();
    QrCodeApi();
    orderAPI();
  }, [interval]);
  useEffect(() => {
    DashboardAPI();
    QrCodeApi();
    orderAPI();
  }, [interval]);

  useEffect(() => {
    if (
      !loading &&
      dashboardData.sent_to_network !== undefined &&
      dashboardData.not_sent_to_network !== undefined
    ) {
      const donut = document.getElementById("donut-chart");
      const total =
        parseInt(dashboardData.sent_to_network) +
        parseInt(dashboardData.not_sent_to_network);
      setPercentage(total);
      const donutFill = (dashboardData.sent_to_network / total) * 440;
      donut.style.strokeDasharray = `${donutFill} 440`;
    }
  }, [dashboardData, loading]);

  const dashboardData1 = {
    total_requests: 358,
    approved: 180,
    pending: 150,
    rejected: 28,
  };
  const total =
    dashboardData1.approved + dashboardData1.pending + dashboardData1.rejected;
  const approvedPercentage = (dashboardData1.approved / total) * 100;
  const pendingPercentage = (dashboardData1.pending / total) * 100;
  const rejectedPercentage = (dashboardData1.rejected / total) * 100;

  const circleCircumference = 2 * Math.PI * 80;
  const approvedDashArray = `${
    (approvedPercentage / 100) * circleCircumference
  } ${circleCircumference}`;
  const pendingDashArray = `${
    (pendingPercentage / 100) * circleCircumference
  } ${circleCircumference}`;
  const rejectedDashArray = `${
    (rejectedPercentage / 100) * circleCircumference
  } ${circleCircumference}`;

  return (
    <>
      <NoIndex />
      <div
        className=" p-3 "
        style={{
          backgroundColor: "#fff",
          fontFamily: "Nunito,sans-serif",
        }}
      >
        
        {/* {JSON.stringify(subscriptionDetails)} */}
        {/* Modal for subscription expiring soon */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Subscription Expiring Soon</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your subscription plan is about to expire in{" "}
            {subscriptionDetails?.remaining_days} days. Please renew it soon to
            avoid interruption.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                /* Add renewal logic here */
              }}
            >
              Renew Now
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="row col-12 m-0 p-0 d-flex  justify-content-start align-items-start">
          {/* Top Banner */}
          <div className="col-12 p-0 m-0 ">
            <div className=" d-flex mx-2 p-0 bg-green border-15-black">
              <div className="col-6 p-4">
                <div className="text-top">
                  Integrated Your System with Anlook and Maximize All Benefits
                </div>
                <div className="pt-2">
                  <Link to="/Profile/Plans">
                    <button type="button" className="blue-button">
                      Upgrade Plan
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-6 p-0 m-0">
                <div
                  className="d-flex p-0 m-0 justify-content-end align-items-center"
                  style={{ overflow: "hidden", height: "180px" }}
                >
                  <img
                    src={TopImage}
                    height={300}
                    alt=""
                    style={{
                      objectFit: "cover",
                      position: "relative",
                      top: "+80px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 m-0 p-0 ">
            {/* Second Order Management*/}
            <div className="p-3 m-2 my-3 bg-white border-15-black dashboard-height d-flex flex-column justify-content-between">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <p className="text-3-2 m-0 p-0">Order Summary</p>
        </div>
        <div>
          <select
            className="p-1 border-15 mx-2"
            value={interval}
            onChange={(e) => setInterval(e.target.value)}
          >
            <option value="7">Last 7 Days</option>
            <option value="30">Last Month</option>
            <option value="365">Last Year</option>
          </select>
        </div>
      </div>

      <hr className="m-0 my-2" />

      {orderAnalytics && (
        <>
          {/* <div>
            <p className="text-3-2-dark">Order Summary</p>
            <p className="text-3-2-gray">
              Description: Order analytics for the selected period.
            </p>
          </div> */}

          <div className="row col-12 m-0 p-0 text-5">
            <div className="col-6 px-0 d-flex justify-content-start">
              Received Orders Amount
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              {orderAnalytics.data1.received_orders_amount}
            </div>

            <div className="col-6 px-0 d-flex justify-content-start">
              Dispatched Orders Amount
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              {orderAnalytics.data1.dispatched_orders_amount}
            </div>

            <div className="col-6 px-0 d-flex justify-content-start">
              Completed Orders Amount
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              {orderAnalytics.data1.completed_orders_amount}
            </div>

            <div className="col-6 px-0 d-flex justify-content-start">
              Received Orders Count
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              {orderAnalytics.data1.received_orders_count}
            </div>

            <div className="col-6 px-0 d-flex justify-content-start">
              Dispatched Orders Count
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              {orderAnalytics.data1.dispatched_orders_count}
            </div>

            <div className="col-6 px-0 d-flex justify-content-start">
              Completed Orders Count
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              {orderAnalytics.data1.completed_orders_count}
            </div>
          </div>
        </>
      )}

      <hr className="m-0 p-0" />

      <div className="d-flex justify-content-start">
        <div className="text-blue underline cursor-pointer">View Analytics</div>
      </div>
    </div>

            {/* Seventh QR */}
            <div className=" p-3 m-2 my-3  bg-white d-flex flex-column  justify-content-between  border-15-black dashboard-height-2">
              <div className="row col-12">
                <div className="col-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={qrCode} alt="" height={120} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex justify-content-center">
                    <p className="text-3-2">Scan to start chat</p>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <div>
                      <button
                        className="blue-button w-100"
                        onClick={() => downloadQRCode(qrCode)}
                      >
                        Download
                      </button>
                    </div>

                    <div className="pt-2">
                      <button className="gray-button w-100">Share</button>
                    </div>
                    {/* <div className="bg-green-filter btn text-white">Share</div> */}
                    {/* <div className="bg-light btn text-dark">Download</div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Sixth Active Plan */}
    <div className="p-3 m-2 my-3 bg-white d-flex flex-column justify-content-between border-15-black dashboard-height-2">
    <div className="d-flex justify-content-between align-items-start">
    {/* Donut Chart Section */}
    <div className="d-flex flex-column justify-content-between align-items-center">
      <div>
        <p className="text-3-2" style={{ marginBottom: "-40px" }}>Active Plan</p>
      </div>

      <div style={{ position: "relative", width: "100%", height: "10rem" }}>
        <Doughnut data={semiDonutData} options={semiDonutOptions} style={{ width: "100%", height: "10rem" }} />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -25%)",
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          <div style={{ fontWeight: "bold" }}>{totalQuote - sentOnNetwork}</div>
          <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>Available Messages</div>
        </div>
      </div>
    </div>

    {/* Upgrade Plan Button Section */}
    <div className="w-100 mx-3 p-1">
      <div className="light-blue border-15-only p-2 d-flex flex-column justify-content-center">
        <div className="text-5">Messages Left : {totalQuote - sentOnNetwork} </div>
        <div className="pt-2">
          <Link to="/Profile/Plans">
            <button type="button" className="blue-button  w-100">
              Upgrade Plan
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>

          </div>
          <div className="col-8  m-0 p-0">
            {/* Third Campaign*/}
            <div className=" p-3  m-2 my-3 bg-white d-flex flex-column  justify-content-between border-15-black dashboard-height">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="text-3-2 m-0 p-0">Lead Generated</p>
                </div>
                <div>
                  <select
                    className="p-1 border-15 mx-2"
                    value={interval}
                    onChange={(e) => setInterval(e.target.value)}
                  >
                    <option value="1">Today</option>
                    <option value="7">Last 7 Days</option>
                    <option value="30">Last Month</option>
                    <option value="365">Last Year</option>
                  </select>
                </div>
              </div>
              <hr className="m-0 p-0" />
              <div className="row m-0 m-0 col-12">
                <div className="col-8">
                  <div className="bg-gred-voilate border-15-only px-1 ">
                    <div className="p-3">
                      <div className="d-flex justify-content-center text-3-2">
                        {/* 0 */}
                      </div>
                      <div className="d-flex justify-content-center">
                        Total Campaign
                      </div>
                    </div>
                    <div>
                      <ResponsiveContainer height={120}>
                        <AreaChart
                          data={data}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <defs>
                            <linearGradient
                              id="colorUv4"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="rgba(230, 229, 251, 1)"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="rgba(254, 253, 255, 1)"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <Tooltip
                            content={<CustomTooltip />}
                            cursor={{ strokeDasharray: "3 3" }}
                          />
                          <Area
                            type="linier"
                            dataKey="uv"
                            stroke="rgba(151, 143, 237, 1)"
                            fill="url(#colorUv4)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex flex-column justify-content-between">
                  <div className="p-2  border-15-only bg-light-yellow">
                    <div>
                      <div className="text-3-2 text-start"> 0</div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "rgba(250, 152, 0, 1)",
                            marginRight: "5px",
                          }}
                        ></span>
                        <span className="text-5 text-gray">
                          Sent To Network
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2   bg-green border-15-only">
                    <div>
                      <div className="text-3-2 text-start">
                        {" "}
                        {dashboardData.sentOnNetwork}
                      </div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "rgba(1, 195, 125, 1)",
                            marginRight: "5px",
                          }}
                        ></span>
                        <span className="text-5 text-gray">Interested</span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2  border-15-only bg-light-red">
                    <div>
                      <div className="text-3-2 text-start">
                        {" "}
                        {dashboardData.notInterested}
                      </div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "rgba(215, 107, 102, 1)",
                            marginRight: "5px",
                          }}
                        ></span>
                        <span className="text-5 text-gray">Not Interested</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-2 p-0" />
                <div className="d-flex justify-content-start">
                  <div className="text-blue underline cursor-pointer ">
                    Lead Details
                  </div>
                </div>
              </div>
            </div>

            {/* Fifth Template */}
            <div className="p-3 m-2 my-3 bg-white d-flex flex-column justify-content-between border-15-black dashboard-height">
              <div>
                <p className="text-3-2 p-0 m-0">Template Status</p>
              </div>
              <hr className="m-0 my-2 p-0" />
              <div className="text-3-2-gray">
                Hurray!! your latest templates was approved by Meta
              </div>

              <div className="d-flex justify-content-between align-items-center light-gray p-2 border-15-only">
                <div className="m-0 p-0">Template001</div>
                <div className="">
                  <button className="blue-outline-button ">
                    {" "}
                    Use Template
                  </button>
                </div>
              </div>

              <div className="text-3-2-gray">Recent Approved Templates</div>
              <div className="row col-12 m-0 p-0  d-flex justify-content-between">
                <div className="col-4 m-0 p-0">
                  <div className="p-2 me-2 light-gray border-15-only ">
                    <div>Temp 1</div>
                    <div className="text-green-text">Approved</div>
                  </div>
                </div>
                <div className="col-4 m-0 p-0">
                  <div className="p-2 mx-1 light-gray border-15-only">
                    <div>Temp 2</div>
                    <div className="text-green-text">Approved</div>
                  </div>
                </div>
                <div className="col-4 m-0 p-0">
                  <div className="p-2 ms-2 light-gray border-15-only">
                    <div>Temp 3</div>
                    <div className="text-green-text">Approved</div>
                  </div>
                </div>
              </div>
              <hr className="my-2 p-0" />
              <div className="d-flex justify-content-start">
                <div className="text-blue underline cursor-pointer ">
                  View All Templates
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <circle id="donut-chart" />
    </>
  );
};

export default Dashboard;
