import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
import signup from "../../images/signup.svg";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";
import author from "../../images/LandingPageImage/Author_img.svg";

import axios from "axios";
import { Link } from "react-router-dom";
const Blogs = ({onSignUpClick, onLoginClick ,country,handleCountryChange }) => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const blogsPerPage = 12;
  useEffect(() => {
    axios
      .post("https://api.goseo.in/api/Seo/GetProductBlogs", { product_name: "anlook" })
      .then((response) => {
        if (response.data.status === "0") {
          const apiData = response.data.data.map((post) => ({
            id: post.post_id,
            image: post.post_featured_image,
            title: post.post_title,
            description: post.post_excerpt,
            author: post.post_author,
            post_slug: post.post_slug,
            date: post.created_at,
          }));
          setBlogData(apiData);
        }
      })
      .catch((error) => console.error("Error fetching blog data:", error))
      .finally(() => setLoading(false));
  }, []);
 
  const totalPages = Math.ceil(blogData.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const filteredBlogs = blogData.filter((blog) =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    blog.description.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const ShimmerBlogCard = () => (
    <div className="card-blog">
      <div className="shimmer-image-blog"></div>
      <div className="shimmer-content ">
        <div className="shimmer-line shimmer-author"></div>
        <div className="shimmer-line shimmer-date"></div>
        <div className="shimmer-line shimmer-title"></div>
        <div className="shimmer-line shimmer-description"></div>
      </div>
    </div>
  );
  return (
    <>
      <Helmet>
        <title>Anlook Blog - See What the World Is Talking About</title>
        <meta
          name="description"
          content="Explore the Anlook Blog for the latest insights, tips, and trends in WhatsApp automation and business communication. Learn how to enhance customer engagement and boost productivity with expert advice from Anlook."
        />
      </Helmet>
      <div className="landing-body-bg">
      <LandingNav  onSignUpClick={onSignUpClick}  onLoginClick={onLoginClick}  country={country} handleCountryChange={handleCountryChange}  />
        <div className="text-2-2 text-center pt-5 px-2">Get Insightful Views About WhatsApp Business</div>
        {/* Search Bar */}
        <div className="container-blog-search pt-4">
          <input
            type="text"
            className="search-bar ps-4"
            placeholder="Search blogs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* Cards */}
        <div className="container-blog">
          <div className="card-container-blog">
            {loading
              ? Array(8).fill().map((_, index) => <ShimmerBlogCard key={index} />)
              : currentBlogs.slice().reverse().map((card) => (
                  <div key={card.id} className="card-blog">
                  <div className="image-container">
                      <img src={card.image} alt={card.title} />
                    </div>
                    <div className="p-2">
                   


                      <div className="pb-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img src={author}  alt="author" height={20} className="me-1" /> <p className="p-0 m-0 text-3-2-gray ">{card.author}</p>
                        </div>
                        {/* <p className="p-0 m-0 text-3-2-gray">
                          {new Date(card.date).toLocaleDateString("en-GB")}
                        </p> */}
                      </div>
                      <p className="card-title">
                        <Link to={`/blog/${card.post_slug}`} className="text-3-2">
                          {card.title}
                        </Link>
                      </p>
                    </div>
                    <p className="card-description pt-3">{card.description.slice(0, 80)}....</p>
                    <p>
                      <Link to={`/blog/${card.post_slug}`} className="read-more">
                        Read more →
                      </Link>
                    </p>
                  </div>
                ))}
          </div>
        </div>

        {/* Pagination */}
        <div className="blogs-pagination-container">
          <button
            className="blogs-pagination-button"
            onClick={() => handleClick(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handleClick(index + 1)}
              className={`blogs-pagination-button ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            className="blogs-pagination-button"
            onClick={() => handleClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Blogs;
