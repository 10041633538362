import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Button, Form, Modal, Tab } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import Select from "react-select";
import "./AdminDashboard.css"
import { Authorblogmodal } from "./Modal";
import { AiOutlineClose } from "react-icons/ai";
const Editblogs = () => {
  const [content, setContent] = useState([]);
  const [Loaddata, setLoaddata] = useState([]);
  const [Editblogdata, setEditblogdata] = useState([]);
  const Editblogid = JSON.parse(sessionStorage.getItem("Editblogid"));
  const [postTempId, setPostTempId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [modalVideoSrc, setModalVideoSrc] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [currentTempId, setCurrentTempId] = useState(null);

  const handleOpenModal = (content, index) => {
    setModalContent(content);
    setCurrentIndex(index);
    setShowModal(true);
  };
  const handleOpenImageModal = (src, index, tempId) => {
    setModalImageSrc(src);
    setCurrentIndex(index);
    setCurrentTempId(tempId);
    setShowImageModal(true);
    console.log("Current Index in Image Modal:", index);
  };

  const handleOpenVideoModal = (src) => {
    setModalVideoSrc(src);
    setShowVideoModal(true);
  };
  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getStyle = (type) => {
    switch (type) {
      case "h1":
        return { fontSize: "2em" };
      case "h2":
        return { fontSize: "1.5em" };
      case "h3":
        return { fontSize: "1.17em" };
      case "h4":
        return { fontSize: "1em" };
      case "h5":
        return { fontSize: "0.83em" };
      case "h6":
        return { fontSize: "0.67em" };
      case "paragraph":
        return { fontSize: "1em" };
      case "orderedList":
      case "unorderedList":
        return { fontSize: "1em" };
      case "quote":
        return { fontSize: "1em", fontStyle: "italic" };
      case "checklist":
        return { fontSize: "1em" };
      case "code":
        return { fontSize: "1em" };
      case "link":
        return { fontSize: "1em", color: "blue", textDecoration: "underline" };

      default:
        return { fontSize: "1em" };
    }
  };
  const [editableContent, setEditableContent] = useState([
    ...content,
    ...Editblogdata,
  ]);

  useEffect(() => {
    console.log("EditableContent:", editableContent);
    setEditableContent([...content, ...Editblogdata]);
  }, [content, Editblogdata]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedContent = [...editableContent];
    updatedContent[index] = { ...updatedContent[index], [name]: value };
    setEditableContent(updatedContent);
  };
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const renderContent = () => {
    return editableContent.map((item, index) => {
      const strippedContent = stripHtmlTags(item.block_content || item.text);
      const sanitizedContent = DOMPurify.sanitize(
        item.block_content || item.text
      );

      let contentElement;

      switch (item.block_type || item.type) {
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
        case "paragraph":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle(item.block_type || item.type)}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "unorderedList":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <ul
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle("unorderedList")}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "numberedList":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <ol
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle("numberedList")}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "quote":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <blockquote
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle("quote")}
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "checklist":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <div key={index} style={getStyle("checklist")}>
                <input
                  className="form-control"
                  type="checkbox"
                  name="text"
                  checked={item.text === "checked"} // Adjust based on your data
                  onChange={(e) => handleInputChange(index, e)}
                />
                <input
                  className="form-control"
                  type="text"
                  name="text"
                  value={item.text}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
            </div>
          );
          break;

        case "code":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <textarea
                name="text"
                value={item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("code")}
              />
              <SyntaxHighlighter
                customStyle={getStyle("code")}
                language={item.language}
                style={vs}
              >
                {item.text}
              </SyntaxHighlighter>
            </div>
          );
          break;

        case "link":
          contentElement = (
            <div
              key={index}
              className="mt-2 p-2"
              style={{ border: "1px solid grey", borderRadius: ".4rem" }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                style={getStyle(item.block_type || item.type)}
                target="_blank"
                onClick={() =>
                  handleOpenModal(item.block_content || item.text, index)
                }
              />
            </div>
          );
          break;

        case "uploadedImage":
          contentElement = (
            <div key={index} className="mt-2">
              <img
                src={item.block_image}
                alt="Uploaded Image"
                style={{ width: "30vw" }}
                onClick={() =>
                  handleOpenImageModal(item.block_image, index, item.temp_id)
                }
              />
            </div>
          );
          break;
        case "uploadedVideo":
          contentElement = (
            <div key={index} className="mt-2">
              <video
                controls
                src={item.block_image || item.src}
                style={{ width: "40vw" }}
                onClick={() =>
                  handleOpenVideoModal(item.block_image, index, item.temp_id)
                }
              />
            </div>
          );
          break;

        default:
          contentElement = null;
      }

      return contentElement;
    });
  };

  const [formState, setFormState] = useState({
    title: "",
    excerpt: "",
    imageUrl: "",
    slug: "",
    imgtitle: "",
    alt: "",
    imgdesc: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      slug:
        name === "title" && !prevState.slugEdited
          ? generateSlug(value)
          : prevState.slug,
    }));
  };
  const handleSlugChange = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      slug: value,
      slugEdited: true,
    }));
  };
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-");
  };
  const handlefeatureImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;

            // Set the uploaded image URL in the formState
            setFormState((prevState) => ({
              ...prevState,
              imageUrl,
            }));

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${imageUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const Geteditloadblog = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          p_post_id: Editblogid.post_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/LoadBlogForEditing`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);
      if (data.status === "0") {
        setLoaddata(data.data || []);
        setPostTempId(data.result[0].post_tempid);
        console.log(data.result[0].post_tempid);
        Geteditblog();
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };
  const Geteditblog = async () => {
    if (!postTempId) return;
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          tempid: postTempId,
        }),
        redirect: "follow",
      };
      console.log(postTempId);
      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogForEditing`,
        requestOptions
      );
      const data = await res.json();

      console.log("Geteditblog response:", data);

      if (data.status === "0") {
        setEditableContent(data.data[0].blogdetails);
        const blogData = data.data[0];

        setFormState({
          title: blogData.post_title,
          excerpt: blogData.post_excerpt,
          featuredImage: blogData.post_featured_image,
          slug: blogData.post_slug,
          imgtitle: blogData.post_featured_image_title,
          alt: blogData.post_featured_image_alt,
          imgdesc: blogData.post_featured_image_desc,
        });
        const category = categories.find(
          (cat) => cat.label === blogData.category_name
        );
        console.log(category);
        const subcategory = subcategories.find(
          (subcat) => subcat.label === blogData.sub_category
        );
        console.log(subcategory);
        if (category) {
          setSelectedCategory(category);
          fetchSubcategories(category.value);
        }
        if (subcategory) {
          setSelectedSubcategory(subcategory);
        }
        const keywordsArray = blogData.post_keywords.split(",").map((kw) => ({
          keyword: kw.trim(),
          id: Date.now() + Math.random(),
        }));
        console.log("Parsed keywords:", keywordsArray);
        setKeywords(keywordsArray);
      } else {
        console.error("Failed to fetch blog details:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  useEffect(() => {
    Geteditloadblog();
    if (postTempId) {
      Geteditblog();
    }
  }, []);
  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchCategories(); // Ensure categories are loaded
      Geteditblog(); // Call this after categories are available
    };

    fetchInitialData();
  }, [postTempId]);
  const [editorContent, setEditorContent] = useState(content);
  useEffect(() => {
    setEditorContent(content);
  }, [content]);
  const editorModules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6] }],
        [{ font: [] }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["bold", "italic", "underline"],
        [{ align: [] }],
        [{ color: [] }],
        [{ align: [] }, { direction: "rtl" }],
        ["code-block"],
        ["clean"],
      ],
      handlers: {
        link: () => handleToolbarButtonClick("Link"),
        image: () => handleToolbarButtonClick("Image"),
        video: () => handleToolbarButtonClick("Video"),
      },
    },
  };
  const handleToolbarButtonClick = (name) => {
    setModalTitle(name);
    setModalShow(true);
  };
  const [modalTitle, setModalTitle] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalUploadedImageUrl, setModalUploadedImageUrl] = useState("");

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;

            // Update state for modal
            setModalUploadedImageUrl(imageUrl);
            setUploadSuccess(true); // Set the flag for successful upload

            // Notify user
            try {
              await swal({
                title: "Success!",
                text: `File uploaded successfully. URL: ${imageUrl}`,
                icon: "success",
                button: "OK",
              });
            } catch (swalError) {
              console.error("Swal error:", swalError);
            }

            console.log("Uploaded Image URL:", imageUrl);
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  console.log(uploadedImageUrl);
  const handleModalSave = async () => {
    console.log("handleModalSave called");
    console.log("currentIndex:", currentIndex);
    if (currentIndex >= 0 && currentIndex < editableContent.length) {
      const currentContent = editableContent[currentIndex];
      console.log("currentContent:", currentContent);

      const requestBody = {
        tempid: currentContent.temp_id,
        temp_post_id: postTempId,
        block_type: currentContent.block_type || "paragraph",
        block_content: modalContent || "NA",
        block_styling: "",
        block_image: uploadedImageUrl
          ? `<img src="${uploadedImageUrl}" alt="Uploaded Image" />`
          : "NA",
        block_order: currentIndex.toString(),
      };

      console.log("requestBody:", requestBody);
      try {
        const response = await fetch(
          "https://api.goseo.in/api/Seo/UploadBlockBlogs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const result = await response.json();

        if (result.status === "0" && result.result[0].result === "Y") {
          swal({
            title: "Success!",
            text: result.result[0].message,
            icon: "success",
            button: "OK",
          });
          Geteditloadblog();
        } else {
          swal({
            title: "Error!",
            text: result.result[0].message || result.msg,
            icon: "error",
            button: "OK",
          });
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
      console.log("Save complete");
      setShowModal(false);
    } else {
      console.error("Invalid currentIndex:", currentIndex);
    }
  };
  const handleModalimageSave = async () => {
    console.log("handleModalSave called");
    console.log("currentIndex:", currentIndex);

    if (currentIndex >= 0 && currentIndex < editableContent.length) {
      const requestBody = {
        tempid: currentTempId,
        temp_post_id: postTempId,
        block_type: "uploadedImage",
        block_content: modalUploadedImageUrl,
        block_styling: "",
        block_image: modalUploadedImageUrl,
        block_order: currentIndex.toString(),
      };

      console.log("requestBody:", requestBody);
      try {
        const response = await fetch(
          "https://api.goseo.in/api/Seo/UploadBlockBlogs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const result = await response.json();

        if (result.status === "0" && result.result[0].result === "Y") {
          swal({
            title: "Success!",
            text: result.result[0].message,
            icon: "success",
            button: "OK",
          });
          Geteditloadblog(); // Refresh content if necessary
          setEditableContent((prevContent) => [
            ...prevContent,
            {
              block_type: "uploadedImage",
              block_image: modalUploadedImageUrl,
              block_order: currentIndex.toString(),
            },
          ]);
        } else {
          swal({
            title: "Error!",
            text: result.result[0].message || result.msg,
            icon: "error",
            button: "OK",
          });
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }

      console.log("Save complete");
      setShowImageModal(false); // Ensure this closes the correct modal
    } else {
      console.error("Invalid currentIndex:", currentIndex);
    }
  };

  console.log(postTempId);
  const Publish = async () => {
    try {
      if (!selectedAuthor) {
        swal("Error", "Please select an author", "error");
        return;
      }
      if (!selectedCategory || !selectedSubcategory) {
        swal("Error", "Please select a category and subcategory", "error");
        return;
      }
      const { title, excerpt, imageUrl, slug, imgtitle, alt, imgdesc } =
        formState;
      const keywordString = keywords.map((kw) => kw.keyword).join(",");

      const raw = JSON.stringify({
        p_temp_post_id: postTempId,
        p_post_title: title,
        p_post_excerpt: excerpt,
        p_post_author: "1",
        p_post_featured_image: imageUrl || formState.featuredImage,
        p_status: "Published",
        p_product_name: "Anlook",
        p_post_keywords: keywordString,
        p_post_featured_image_title: imgtitle,
        p_post_featured_image_desc: imgdesc,
        p_post_featured_image_alt: alt,
        p_post_category: selectedCategory.value,
        p_post_subcategory: selectedSubcategory.value,
        p_slug: slug,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/publishBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const blogResult = data.result[0].result;
        const blogMessage = data.result[0].message;

        swal({
          title: "Success!",
          text: `${data.msg} - ${blogMessage}`,
          icon: "success",
          button: "OK",
        });
        Geteditblog();
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: `Publish failed: ${error.message}`,
        icon: "error",
        button: "OK",
      });
    }
  };
  console.log(modalContent);

  // author
  const [Authormodal, setAuthormodal] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);

  const GetAuthorsBlog = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetAuthorsBlog`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);

      if (data.status === "0") {
        setAuthors(
          data.data.map((author) => ({
            label: `${author.fullname}`,
            value: author.id,
          }))
        );
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };
  useEffect(() => {
    GetAuthorsBlog();
  }, []);

  const handleAuthorChange = (selected) => {
    if (selected.value === "addAuthor") {
      setAuthormodal(true);
    } else {
      setSelectedAuthor(selected);
    }
  };
  // keyword
  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const getSuggestedKeywords = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };
      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogKeywords`,
        requestOptions
      );
      const data = await res.json();
      console.log("Suggested Keywords response:", data);

      if (data.status === "0") {
        setSuggestedKeywords(data.data);
      } else {
        console.error("Failed to fetch suggested keywords:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching suggested keywords:", error);
    } finally {
    }
  };
  useEffect(() => {
    getSuggestedKeywords();
  }, []);
  useEffect(() => {
    if (keyword) {
      const filtered = suggestedKeywords.filter((suggestion) =>
        suggestion.keyword.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  }, [keyword, suggestedKeywords]);
  const handleSelectSuggested = (suggestedKeyword) => {
    setKeywords((prev) => [
      ...prev,
      { id: suggestedKeyword.id, keyword: suggestedKeyword.keyword },
    ]);
    setKeyword("");
  };

  const handleAddKeyword = (kw = keyword) => {
    if (kw) {
      const newKeywords = kw
        .split(",")
        .map((kw) => kw.trim())
        .filter(
          (kw) => kw && !keywords.some((existing) => existing.keyword === kw)
        );

      setKeywords([
        ...keywords,
        ...newKeywords.map((kw) => ({
          keyword: kw,
          id: Date.now() + Math.random(),
        })),
      ]);

      newKeywords.forEach((newKw) => {
        Insertkeywords(newKw);
      });

      setKeyword("");
    }
  };

  const handleKeywordPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddKeyword();
    }
  };
  const handleRemoveKeyword = (id) => {
    setKeywords((prevKeywords) => prevKeywords.filter((kw) => kw.id !== id));
  };
  const Insertkeywords = async (kw) => {
    try {
      const raw = JSON.stringify({
        tempid: Editblogid.post_id,
        keyword: kw,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/InsertKeywordsBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const result = data.result[0];
        if (result && result.result === "Y") {
          console.log(result.message);
        } else {
          console.log("Unexpected result format");
        }
      } else {
        console.log(data.msg);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  // category

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const fetchCategories = async () => {
    try {
      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setCategories(
          data.data.map((category) => ({
            label: category.category_name,
            value: category.id,
          }))
        );
      } else {
        console.error("Failed to fetch categories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchSubcategories = async (categoryId) => {
    try {
      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogSubCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ categoryid: categoryId }),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setSubcategories(
          data.data.map((subcategory) => ({
            label: subcategory.sub_category,
            value: subcategory.id,
          }))
        );
      } else {
        console.error("Failed to fetch subcategories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setSubcategories([]);
    setSelectedSubcategory(null);
    fetchSubcategories(selectedOption.value);
  };

  const handleSubcategoryChange = (selectedOption) => {
    setSelectedSubcategory(selectedOption);
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="marginRight p-4">
      <Authorblogmodal
        Authormodal={Authormodal}
        setAuthormodal={setAuthormodal}
        GetAuthorsBlog={GetAuthorsBlog}
      />

      <div className="row mt-0 pt-0 pb-0">
        <div className="col">
          <h1 className="text-dark mb-4 ps-1">Edit Blogs</h1>
        </div>
        <div className="col d-flex justify-content-end">
          <Button
            variant="primary"
            size="sm"
            type="submit"
            style={{ height: "2rem" }}
            onClick={() => Publish()}
          >
            Save as Draft
          </Button>{" "}
          <Button
            style={{ background: "#fff", border: "none" }}
            size="sm"
            type="submit"
          ></Button>
          <Button
            variant="primary"
            size="sm"
            type="submit"
            style={{ height: "2rem" }}
            onClick={() => Publish()}
          >
            Publish
          </Button>{" "}
          <Button
            style={{ background: "#fff", border: "none" }}
            size="sm"
            type="submit"
          ></Button>
          <Link to="/admin/Allblogs">
            <Button size="sm" variant="primary">
              Back
            </Button>
          </Link>
        </div>
      </div>

      <Form className="border rounded-3 p-3" style={{ backgroundColor: "rgb(1,195,125,0.1)" }}>
        <Form.Group className="" controlId="formTitle">
          <Form.Label className=" mr-2">Title</Form.Label>
          <Form.Control
          className="mt-1"
            type="text"
            placeholder="Enter title"
            name="title"
            value={formState.title}
            onChange={handleChange}
          />{" "}
        </Form.Group>
        <Form.Group className="mt-2" controlId="formslug">
          <Form.Label className="mr-2">Slug</Form.Label>
          <Form.Control
         
            as="textarea"
            rows={1}
            placeholder="Add slug"
            name="slug"
            value={formState.slug}
            onChange={handleSlugChange}
          />{" "}
        </Form.Group>
        <Form.Group className="mt-2" controlId="formExcerpt">
          <Form.Label className="mb-0 mr-2">Excerpt</Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add excerpt"
            name="excerpt"
            value={formState.excerpt}
            onChange={handleChange}
          />{" "}
        </Form.Group>
        <div className="row mt-2">
          <Form.Group className="col-md-3 col-6 " controlId="formImage">
            <Form.Label className=" mr-2">Feature image</Form.Label>
            <div className="d-flex align-items-center">
              {formState.featuredImage && (
                <img
                  src={formState.featuredImage}
                  alt="Featured"
                  style={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "1rem",
                  }}
                />
              )}
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handlefeatureImageUpload}
                className="flex-grow-1"
                style={{ maxWidth: "200px" }}
              />
            </div>
          </Form.Group>
          <Form.Group className="col-md-3 col-6" controlId="formExcerpt">
            <Form.Label className=" mr-2">Feature Image Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Add Title"
              name="imgtitle"
              value={formState.imgtitle}
              onChange={handleChange}
            />{" "}
          </Form.Group>
          <Form.Group className="col-md-3 col-6" controlId="formExcerpt">
            <Form.Label className=" mr-2"> Feature Image Alt</Form.Label>
            <Form.Control
              type="text"
              placeholder="Add Alt"
              name="alt"
              value={formState.alt}
              onChange={handleChange}
            />{" "}
          </Form.Group>
          <Form.Group className="col-md-3 col-6" controlId="formExcerpt">
            <Form.Label className=" mr-2">
              Feature Image Description
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Add Description"
              name="imgdesc"
              value={formState.imgdesc}
              onChange={handleChange}
            />{" "}
          </Form.Group>
        </div>
      </Form>
      <div className="row">
        <div className=" col-md-9 col-12 px-md-2">
          <div className="">{renderContent()}</div>
        </div>
        <div className="col-md-3 p-0 col-12">
          <div className="card shadow-none w-100 mt-2 p-2 mb-2" style={{ backgroundColor: "rgb(1,195,125,0.1)" }}>
            <h6 className="text-center">
              <strong>Author</strong>{" "}
            </h6>
            <Select
              options={[
                { label: "Add Author", value: "addAuthor" },
                ...authors,
              ]}
              value={selectedAuthor}
              onChange={handleAuthorChange}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                menu: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: 200,
                  overflowY: "auto",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: ".9rem",
                }),
              }}
            />
          </div>
          <div className="card shadow-none w-100 p-2 mb-2" style={{ backgroundColor: "rgb(1,195,125,0.1)" }}>
            <h6 className="text-center">
              <strong>Category</strong>{" "}
            </h6>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              options={categories}
              placeholder=" Category"
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                menu: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: 200,
                  overflowY: "auto",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: ".9rem",
                }),
              }}
            />

            {selectedCategory && (
              <>
                <h6 className="mt-3">Select Subcategory</h6>
                <Select
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  options={subcategories}
                  placeholder="Select Subcategory"
                  isDisabled={subcategories.length === 0}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: 200,
                      overflowY: "auto",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: ".9rem",
                    }),
                  }}
                />
              </>
            )}
          </div>
          <div className="card shadow-none w-100 p-2 mb-2" style={{ backgroundColor: "rgb(1,195,125,0.1)" }}>
            <h6 className="text-center">
              <strong>Keywords</strong>{" "}
            </h6>
            <Form inline className="">
              <Form.Control
                type="text"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                placeholder="Enter keywords separated by commas"
                className="mr-2"
                style={{ width: "100%" }}
                onKeyPress={handleKeywordPress} // Call function on Enter key press
              />
            </Form>
            {filteredSuggestions.length > 0 && (
              <div className="suggested-keywords mt-2">
                <strong>Suggested Keywords:</strong>
                <div className="d-flex flex-wrap">
                  {filteredSuggestions.map((suggested) => (
                    <div
                      key={suggested.id}
                      className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSelectSuggested(suggested)}
                    >
                      {suggested.keyword}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Tab.Container id="keyword-tabs" defaultActiveKey="keywords">
              <Tab.Content>
                <Tab.Pane eventKey="keywords">
                  <div className="d-flex flex-wrap">
                    {keywords.length > 0 ? (
                      keywords.map((kw) => (
                        <div
                          key={kw.id}
                          className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                          style={{
                            position: "relative",
                            margin: "5px",
                            padding: "5px 10px",
                          }}
                        >
                          {kw.keyword} &nbsp; &nbsp;
                          <AiOutlineClose
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveKeyword(kw.id)}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="text-center mt-4">No keywords found</div>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Content Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactQuill
            modules={editorModules}
            value={modalContent}
            onChange={(content) => setModalContent(content)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={handleModalSave}>
            Save Changes
          </Button>
          <Button size="sm" variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Image Modal */}
      <Modal show={showImageModal} onHide={handleCloseImageModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalImageSrc} alt="Modal Image" style={{ width: "50%" }} />
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={handleModalimageSave}>
            Save Changes
          </Button>
          <Button size="sm" variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Video Modal */}
      <Modal show={showVideoModal} onHide={handleCloseVideoModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Video Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video controls src={modalVideoSrc} style={{ width: "100%" }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleCloseVideoModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Editblogs;
