import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import avatar from "../../images/Contact22.png";
import Swal from "sweetalert2";
import "./AdminDashboard.css";
import { useNavigate } from "react-router-dom";

import rupee from "../../images/rupee.svg";
import Calendar from "../../images/calendar.svg";
import DownArrow from "../../images/arrow-down.svg";

import person from "../../images/Person IMG.svg";
import { kimbieLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import "./AdminDashboard.css"

const ClientAccordionTable = ({Admin}) => {
  const [openRows, setOpenRows] = useState({});
  const [Clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [userID, setuserID] = useState("");
  const [Lerror, setLerror] = useState(null);
  const [User, setUser] = useState({});
  const [name, setName] = useState("");
  const [appId, setAppId] = useState("");
  const [businessAccId, setBusinessAccId] = useState("");
  const [token, setToken] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [Credentials, setCredentials] = useState();

  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    // Check if the Geolocation API is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Update latitude and longitude states
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setLerror(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);
  const toggleRow = (id) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const handleNavigate = (website) => {
    window.open(website, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    // Retrieve user data from sessionStorage
    const userData = sessionStorage.getItem("AdminUser");
    if (userData) {
      setUser(JSON.parse(userData));
      console.log(User.pkid);
    } else {
      console.log("No user data found in sessionStorage.");
    }
  }, []);
  // fetch All Client Api call
  const FetchClientCall = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const UserDetail = {
        // userid: User.pkid,
        userid: Admin.pkid,
        latitude: latitude,
        longitude: longitude,
      };

      const response = await axios.post(
        "https://api.anlook.com/api/Admin/getAllClientList",
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);

        setClients(response.data.response);
        console.log(response.data.response);
        console.log(Clients);
        setLoading(false);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("error");
      console.error("Error updating Admin login:", error);
    } finally {
      setLoading(false);
    }
  };
  // fetch Client previous Credential Api Call
  const FetchClientCredentialsCall = async (userId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const UserDetail = {
        userid: userId,
        secret: "string",
        fromdate: "string",
        todate: "string",
        status: ["string"],
      };

      const response = await axios.post(
        "https://api.anlook.com/api/Whatsapp/getWhatsappCredentials",
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        setCredentials(response.data.response);
        console.log(Credentials);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("error");
      console.error("Error updating Admin login:", error);
    } finally {
    }
  };

  // fetch Deactivate Client Api call

  const DeactivateClientAPI = async (usCode) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with the API request
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
            },
          };
          const requestBody = {
            adminId: Admin.pkid,
            userid: usCode,
            latitude: latitude,
            longitude: longitude,
          };

          axios
            .post(
              "https://api.anlook.com/api/Admin/client/deactivateClient",
              requestBody,
              config
            )
            .then((response) => {
              // Handle success
              console.log(response);
              FetchClientCall();
              // RunningOrder();
              Swal.fire(
                "Deactivated!",
                "This client has been deactivated.",
                "success"
              );
            })
            .catch((error) => {
              // Handle error here
              console.error("Error during the API request:", error);
              Swal.fire(
                "Error!",
                "Something went wrong with accepting the order.",
                "error"
              );
            });
        } catch (error) {
          console.error("Catch block error:", error);
          Swal.fire("Error!", "Something went wrong.", "error");
        }
      }
    });
  };
  // fetch Activate Client Api call

  const ActivateClientAPI = async (usCode) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Activate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with the API request
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
            },
          };
          const requestBody = {
            adminId: Admin.pkid,
            userid: usCode,
            latitude: latitude,
            longitude: longitude,
          };

          axios
            .post(
              "https://api.anlook.com/api/Admin/client/activateClient",
              requestBody,
              config
            )
            .then((response) => {
              // Handle success
              console.log(response);
              FetchClientCall();
              // RunningOrder();
              Swal.fire(
                "Activated!",
                "This client has been Activated.",
                "success"
              );
            })
            .catch((error) => {
              // Handle error here
              console.error("Error during the API request:", error);
              Swal.fire(
                "Error!",
                "Something went wrong with accepting the order.",
                "error"
              );
            });
        } catch (error) {
          console.error("Catch block error:", error);
          Swal.fire("Error!", "Something went wrong.", "error");
        }
      }
    });
  };
  // Update Client Credentials Api call

  const CredentialUpdateAPI = async (usCode) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with the API request
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
            },
          };
          const requestBody = {
            adminId: Admin.pkid,
            latitude: latitude,
            longitude: longitude,
            userid: userID,
            appId: appId,
            businessAccId: businessAccId,
            token: token,
            phoneNoId: phoneNo,
          };

          axios
            .post(
              "https://api.anlook.com/api/Admin/client/configureWhatsappCredentials",
              requestBody,
              config
            )
            .then((response) => {
              // Handle success
              console.log(response);
              FetchClientCall();
              // RunningOrder();
              Swal.fire(
                "Updated!",
                "The credentials has been updated.",
                "success"
              );
            })
            .catch((error) => {
              // Handle error here
              console.error("Error during the API request:", error);
              Swal.fire(
                "Error!",
                "Something went wrong with accepting the order.",
                "error"
              );
            });
          setuserID("");
        } catch (error) {
          console.error("Catch block error:", error);
          Swal.fire("Error!", "Something went wrong.", "error");
        }
      }
    });
  };

  // useEffect Call for all Apis

  useEffect(() => {
    FetchClientCall();
  }, []);
  useEffect(() => {
    setAppId(Credentials?.app_id);
    setBusinessAccId(Credentials?.business_acc_id);
    setPhoneNo(Credentials?.phone_no_id);
    setToken(Credentials?.token);
  }, [Credentials]);
  //  navigation for the new dashboard page
  const navigate = useNavigate();
  const viewDashboard = (clientId) => {
    // Navigate to the client's dashboard page with the client ID
    navigate(`/admin/ClientDashboard/${clientId}`);
  };

  return (
    <div>
      <div
        className="marginRight px-4 mt-4 bg-white"
        // style={{ width: "80%" }}
      >
        <h2 className="text-center text-dark ">All Clients</h2>
        <hr />

        {Loading ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-grow text-success " role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="table-container">
            {Clients?.map((client) => (
              <div key={client.id} className="table-row">
                <div
                  className="row-header"
                  onClick={() => toggleRow(client.id)}
                >
                  <div className="client-info">
                    <img
                      src={person}
                      alt={`${client.name} avatar`}
                      className="avatar"
                    />
                    <div>
                      <h3>{client.us_name}</h3>
                      <p>{client.us_code}</p>
                    </div>
                  </div>
                  <div className="client-summary">
                    <span className="d-flex">
                      <img src={Calendar} alt="" className="me-1" />{" "}
                      <p className="mt-3">{client.us_last_login.slice(0, 9)}</p>
                    </span>
                    <span className="d-flex">
                      <img src={rupee} alt="" className="me-1" />{" "}
                      <p className="mt-3">{client.us_email}</p>
                    </span>
                    <span
                      className={`chevron ${
                        openRows[client.id] ? "up" : "down"
                      }`}
                    >
                      <img src={DownArrow} alt="" />
                    </span>
                  </div>
                </div>
                {openRows[client.id] && (
                  <div className="row-details">
                    <div className="details-grid">
                      <div>
                        <p>
                          <strong>Contact:</strong> {client.us_name}
                        </p>
                        <p>
                          <strong>Email:</strong> {client.us_email}
                        </p>
                        <p>
                          <strong>Phone:</strong> {client.us_loginid}
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong className="me-2">Actions:</strong>
                          {client.us_status === "1" ? (
                            <button
                              onClick={() =>
                                DeactivateClientAPI(client.us_code)
                              }
                              className="btn btn-outline-danger"
                            >
                              Deactivate Client
                            </button>
                          ) : (
                            <button
                              onClick={() => ActivateClientAPI(client.us_code)}
                              className="btn btn-outline-success"
                            >
                              Activate Client
                            </button>
                          )}
                          <button
                            className="btn btn-outline-secondary mx-2"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => {
                              setuserID(client.us_code);
                              FetchClientCredentialsCall(client.us_code);
                            }}
                          >
                            Update Whatsapp Credential
                          </button>
                          <button
                            title="On Working"
                            className="blue-outline-button mt-2"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the row from being toggled when clicking the button
                              viewDashboard(client.us_code);
                            }}
                          >
                            View Dashboard
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* modal form */}
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                Update Credentials of {userID}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="mb-3">
                <label htmlFor="appId" className="form-label">
                  App ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="appId"
                  placeholder="Enter your App ID"
                  value={appId}
                  onChange={(e) => setAppId(e.target.value)}
                  required
                />
              </div>

              {/* Business Account ID Field */}
              <div className="mb-3">
                <label htmlFor="businessAccId" className="form-label">
                  Business Account ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="businessAccId"
                  placeholder="Enter your Business Account ID"
                  value={businessAccId}
                  onChange={(e) => setBusinessAccId(e.target.value)}
                  required
                />
              </div>

              {/* Token Field */}
              <div className="mb-3">
                <label htmlFor="token" className="form-label">
                  Token
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="token"
                  placeholder="Enter your Token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  required
                />
              </div>

              {/* Phone Number Field */}
              <div className="mb-3">
                <label htmlFor="phoneNo" className="form-label">
                  Phone Number ID
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNo"
                  placeholder="Enter your Phone Number"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-outline-primary"
                onClick={() => CredentialUpdateAPI()}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientAccordionTable;
